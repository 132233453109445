import React, { useState } from 'react';
import { compose } from '@shakacode/recompose';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Icon, Typography } from '@popmenu/common-ui';
import { Help } from '@popmenu/web-icons';
import { FormattedMessage } from 'react-intl';
import { useMutation } from '~/lazy_apollo/client';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { withIntl } from '../../../../utils/withIntl';
import { formatCurrency, tabName, toFloat } from '../../../../utils/utils';
import { classNames, withStyles } from '../../../../utils/withStyles';
import styles from './styles';

import closeMenuItemCartTabMutation from '../../../../libs/gql/mutations/menu_item_carts/closeMenuItemCartTabMutation.gql';
import CodeInputBox from '../../../online_ordering/CodeInputBox';
import BasicForm, { CheckBoxGroup } from '../../../../admin/shared/forms/BasicForm';
import { getExpectedTotal, getFeeAmount, TIP_OPTIONS } from '../SubmitMenuItemCartForm/utils/common';
import { appendOnlineConversionScripts } from '../../../../utils/conversion';
import { trackFacebookEvent } from '../../../../utils/fbq';
import TipOptions from '../SubmitMenuItemCartForm/MenuItemCartForm/TipOptions';
import { PaymentMethod } from '../SubmitMenuItemCartForm/MenuItemCartForm';
import BasicFormConfirmModal from '../../../../admin/shared/forms/BasicForm/BasicFormConfirmModal';
import Loading from '../../../../shared/Loading';
import MenuItemCartStripePaymentFields from '../MenuItemCartStripePaymentFields';
import VipSVG from '../../../../assets/svg/vip.svg';
import FollowDisclaimer from '../../../../shared/sessions/FollowDisclaimer/FollowDisclaimer';
import { resetMenuItemCartData } from '../../../../shared/MenuItemCartActions';
import { handleNotFound } from '../MenuItemCartHelpers';
import { AccordionDiv } from '../SubmitMenuItemCartHelpers';
import MarketingOptInRequest from '../../../../shared/sessions/MarketingOptInRequest';
import { AH, AHLevelProvider } from '../../../shared/AccessibleHeading';

let shouldSubmitMenuItemCart = false;

const MenuItemCartStripeCloseTab = ({
  classes,
  closeTab,
  currentUser,
  customPageUrl,
  menuItemCart,
  restaurant,
  showSnackbarError,
  t,
}) => {
  const [closeMenuItemCartTab] = useMutation(closeMenuItemCartTabMutation);
  const [submitting, setSubmitting] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const dispatch = useDispatch();

  const stripe = useStripe();
  const stripeElements = useElements();

  if (menuItemCart.location.isStripePaymentAvailable && !stripe) {
    // Wait for Stripe to finish loading when it's expected to be available
    return <Loading size="lg" />;
  }

  const submitMutation = ({ paymentInput, shouldCreateFollower, subscribeEmail, subscribeSms, ...variables }) => {
    const expectedTotal = getExpectedTotal(menuItemCart, variables).total;
    closeMenuItemCartTab({
      variables: {
        expectedTotal,
        isConsumerClosingTab: true,
        menuItemCartId: menuItemCart.id,
        menuItemCartInput: {
          ...variables,
          stripeTipAmount: toFloat(variables.stripeTipAmount),
        },
        paymentInput,
        shouldCreateFollower: !!shouldCreateFollower,
        submitMenuItemCart: shouldSubmitMenuItemCart,
        userInput: {
          isSmsUnsubscribed: !subscribeSms,
          isUnsubscribed: !subscribeEmail,
        },
      },
    }).then(() => {
      setSubmitting(false);
      if (shouldSubmitMenuItemCart) {
        appendOnlineConversionScripts({
          customScripts: restaurant.customConversionScripts,
          expectedCartTotal: expectedTotal,
          googleAdsConversionActionScripts: restaurant.googleAdsConversionActionScripts,
        });
        trackFacebookEvent('Purchase', { currency: 'USD', value: expectedTotal });
        resetMenuItemCartData(); // Todo: test with dispatch()
      }
      if (customPageUrl) {
        window.location.href = `${customPageUrl}?location=${menuItemCart.location.slug}#checkout`;
      }
    }).catch((err) => {
      const gqlError = err.graphQLErrors && err.graphQLErrors[0];
      // Handle payment error by passing unique PaymentIntent secret back to the Stripe instance
      if (gqlError && gqlError.stripeClientSecret) {
        const stripeAction = gqlError.stripeIntentStatus === 'requires_source_action' ? stripe.confirmCardPayment(gqlError.stripeClientSecret) : stripe.handleCardAction(gqlError.stripeClientSecret);
        stripeAction.then((actionResult) => {
          if (actionResult.error) {
            showSnackbarError(actionResult.error);
            setSubmitting(false);
          } else {
            // Submit again after error is handled (Stripe SMS verification, card fraud alert, etc)
            submitMutation({
              ...variables,
              paymentInput: {
                ...paymentInput,
                stripePaymentIntentId: actionResult.paymentIntent.id,
              },
              shouldCreateFollower,
            });
          }
        }).catch((actionErr) => {
          console.warn(`[POPMENU] Stripe error: ${actionErr.toString()}`);
          showSnackbarError('Sorry, there was an error verifying the payment method');
          setSubmitting(false);
        });
      } else {
        showSnackbarError(err);
        setSubmitting(false);
        handleNotFound(err, dispatch);
      }
    });
  };

  return (
    <BasicForm
      style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}
      defaultValues={{
        email: menuItemCart.email,
        paymentMethod: 'stripe_payment_method',
        shouldCreateFollower: !restaurant.isMarketingOptInEnabled && !currentUser,
        stripeTipAmount: menuItemCart.location.isOrderingTipsEnabled ? menuItemCart.stripeTipAmount : null,
        subscribeEmail: true,
        subscribeSms: true,
      }}
      onSubmit={({ confirmed, ...variables }) => {
        if (submitting) {
          return;
        }

        // Display confirmation modal on submit
        if (shouldSubmitMenuItemCart && !confirmed) {
          setShowConfirmModal(true);
          return;
        }

        // Manually manage loading state across Stripe + mutation promises
        setSubmitting(true);

        const totals = getExpectedTotal(menuItemCart, variables);
        // Only submit credit card info when submitting cart, otherwise it will be lost anyways
        if (shouldSubmitMenuItemCart && variables.paymentMethod === 'stripe_payment_method' && totals.total > 0) {
          // First create Stripe::PaymentMethod before submitting mutation
          const cardElement = stripeElements.getElement(CardElement);
          stripe.createPaymentMethod({
            card: cardElement,
            type: 'card',
          }).then(({ error, paymentMethod: stripePaymentMethod }) => {
            if (error) {
              showSnackbarError(error.message);
              setSubmitting(false);
            } else {
              submitMutation({
                ...variables,
                paymentInput: {
                  stripePaymentMethodId: stripePaymentMethod.id,
                  stripePublishableKey: stripe._apiKey,
                },
                paymentMethod: 'stripe_payment_method',
              });
            }
          }).catch((err) => {
            showSnackbarError(err);
            setSubmitting(false);
          });
        } else {
          // Or just submit the mutation
          submitMutation({ ...variables });
        }
      }}
    >
      {({ submitForm, values }) => {
        const isStripePaymentMethod = values.paymentMethod === 'stripe_payment_method';
        const feeAmount = getFeeAmount(menuItemCart, values);
        const totals = getExpectedTotal(menuItemCart, values);
        const tipAmount = values.stripeTipAmount || 0;
        return (
          <React.Fragment>
            <div className={classes.closeMyTab}>
              <AH typography align="center" variant="h5" className={classes.closeMyTabText}>
                <FormattedMessage id="consumer.ordering.close_tab" defaultMessage="Close My Tab" />
              </AH>
              <Typography align="center">
                {!menuItemCart.location.isOrderingDineInRemoveTableLabelEnabled && (
                  <FormattedMessage
                    id="consumer.ordering.tab_label"
                    defaultMessage="{table_label}: {table} | "
                    values={{
                      table: <span className={classes.tabName}>{menuItemCart.dineInTableNumber}</span>,
                      table_label: menuItemCart.location.orderingDineInTableNumberLabel ? menuItemCart.location.orderingDineInTableNumberLabel : t('models.menu_item_cart.table_label'),
                    }}
                  />
                )}
                <FormattedMessage
                  id="consumer.ordering.table_tab_label"
                  defaultMessage="{tab_label}: {tab}"
                  values={{
                    tab: t('models.menu_item_cart.tab_label'),
                    tab_label: <span className={classes.tabName}>{tabName(menuItemCart.name || 'N/A')}</span>,
                  }}
                />
              </Typography>
            </div>
            <AHLevelProvider>
              <div className={classes.primaryInfoBox}>
                <Typography align="center" className={classes.primaryInfoBoxText}>
                  <FormattedMessage id="consumer.ordering.payment_warning_body" defaultMessage="Payment info is required to close your tab!" />
                </Typography>
              </div>
              <div className={classes.paymentDescriptionBox}>
                <PaymentMethod
                  menuItemCart={menuItemCart}
                  isOnlinePaymentAvailable={menuItemCart.location.isStripePaymentAvailable}
                >
                  {isOnlinePaymentAvailable => (
                    isOnlinePaymentAvailable && (
                      <React.Fragment>
                        {(isStripePaymentMethod && totals.total > 0) && (
                          <MenuItemCartStripePaymentFields />
                        )}
                        {(isStripePaymentMethod && totals.total === 0) && (
                          <Typography className={classes.pickupDisclaimer}>
                            <FormattedMessage id="consumer.ordering.zero_balance_disclaimer" defaultMessage="Checkout balance is $0, no online payment required" />
                          </Typography>
                        )}
                      </React.Fragment>
                    )
                  )}
                </PaymentMethod>
              </div>
              <AccordionDiv
                menuItemCart={menuItemCart}
                classes={classes}
              />
              {/* VIP box */}
              {!currentUser && menuItemCart.email && (
                <div className={classes.vipBox}>
                  <div className={classes.vipCheckboxWrapper}>
                    <CheckBoxGroup
                      className={classes.vipCheckboxTitle}
                      field="shouldCreateFollower"
                      title={t('consumer.ordering.become_vip')}
                    />
                    <Icon icon={VipSVG} style={{ height: '100%', width: '30%' }} />
                  </div>
                  <div>
                    {restaurant.isMarketingOptInEnabled && values.shouldCreateFollower && <MarketingOptInRequest isCheckout isDineIn />}
                  </div>
                  <div className={classes.followDisclaimer}>
                    <FollowDisclaimer align="left" />
                  </div>
                </div>
              )}
              {/* end VIP box */}
              <CodeInputBox
                classes={classes}
                menuItemCart={menuItemCart}
                restaurant={restaurant}
              />
              {/* subtotal and tip box */}
              {!!menuItemCart.subtotal && (
                <React.Fragment>
                  <TipOptions defaultTipOption={menuItemCart.location.defaultTipOption} menuItemCart={menuItemCart} tipAmount={tipAmount} tipField="stripeTipAmount" tipOptions={TIP_OPTIONS} />
                  <div className={classes.totalContainer}>
                    <div className={classes.menuItemValueContainer}>
                      <Typography className={classes.menuItemValue}>
                        <span>
                          <FormattedMessage id="consumer.ordering.subtotal_title" defaultMessage="Subtotal" />
                        </span>
                        <span>
                          {formatCurrency(menuItemCart.subtotal, 'usd', { showDecimals: true, showSymbol: true })}
                        </span>
                      </Typography>
                    </div>
                    <br />
                    {menuItemCart.selectedOrderingOfferCode && (
                      <React.Fragment>
                        <div className={classes.menuItemValueContainer}>
                          <Typography className={classes.menuItemDiscount}>
                            <span>
                              <FormattedMessage id="consumer.ordering.offer_title" defaultMessage="Offer" />
                            </span>
                            <span>
                              <FormattedMessage
                                id="consumer.ordering.offer_discount"
                                defaultMessage="-{offer_discount}"
                                values={{
                                  offer_discount: formatCurrency(menuItemCart.discountAmount || 0, 'usd', { showDecimals: true, showSymbol: true }),
                                }}
                              />
                            </span>
                          </Typography>
                        </div>
                        <br />
                      </React.Fragment>
                    )}
                    {!!feeAmount && (
                      <React.Fragment>
                        <div className={classes.menuItemValueContainer}>
                          <Typography className={classes.menuItemValue}>
                            <span>
                              <FormattedMessage id="consumer.ordering.fees_title" defaultMessage="Fees" />
                              <Icon
                                className={classes.feesIcon}
                                icon={Help}
                                tooltip={t('consumer.ordering.online_ordering_fee')}
                              />
                            </span>
                            <span>
                              {formatCurrency(feeAmount, 'usd', { showDecimals: true, showSymbol: true })}
                            </span>
                          </Typography>
                        </div>
                        <br />
                      </React.Fragment>
                    )}
                    {!!menuItemCart.onlineTaxAmount && (
                      <React.Fragment>
                        <div className={classes.menuItemValueContainer}>
                          <Typography className={classes.menuItemValue}>
                            <span>
                              <FormattedMessage id="consumer.ordering.taxes_title" defaultMessage="Taxes" />
                            </span>
                            <span>
                              {formatCurrency(menuItemCart.onlineTaxAmount, 'usd', { showDecimals: true, showSymbol: true })}
                            </span>
                          </Typography>
                        </div>
                        <br />
                      </React.Fragment>
                    )}
                    {menuItemCart.location.isOrderingTipsEnabled && (
                      <React.Fragment>
                        <div className={classes.menuItemValueContainer}>
                          <Typography className={classes.menuItemValue}>
                            <span>
                              <FormattedMessage id="consumer.ordering.tips_title" defaultMessage="Tips" />
                            </span>
                            <span>
                              {values.stripeTipAmount ? formatCurrency(toFloat(values.stripeTipAmount), 'usd', { showDecimals: true, showSymbol: true }) : '$0.00'}
                            </span>
                          </Typography>
                        </div>
                        <br />
                      </React.Fragment>
                    )}
                    {menuItemCart.selectedGiftCard && (
                      <div className={classes.giftCardContainer}>
                        <Typography className={classes.menuItemValue}>
                          <span>
                            <FormattedMessage id="consumer.gift_cards.gift_card_title" defaultMessage="Gift Card" />
                          </span>
                          <span>
                            <FormattedMessage
                              id="consumer.gift_cards.gift_card_discount"
                              defaultMessage="-{gift_card_discount}"
                              values={{
                                gift_card_discount: formatCurrency(totals.giftCardDiscountAmount, 'usd', { showDecimals: true, showSymbol: true }),
                              }}
                            />
                          </span>
                        </Typography>
                        <div className={classes.giftCardRemainingBalance}>
                          <span><FormattedMessage id="consumer.gift_cards.remaining_balance" defaultMessage="Remaining Balance" /></span> {formatCurrency(menuItemCart.selectedGiftCard.giftCard.amount - totals.giftCardDiscountAmount, 'usd', { showDecimals: true, showSymbol: true })}
                        </div>
                      </div>
                    )}
                    <div className={classes.dashedDivider} />
                    <div className={classes.menuItemValueContainer}>
                      <Typography className={classes.menuItemTotal}>
                        <FormattedMessage
                          id="consumer.ordering.order_total"
                          defaultMessage="Total: {order_total}"
                          values={{
                            order_total: formatCurrency(totals.total, 'usd', { showDecimals: true, showSymbol: true }),
                          }}
                        />
                      </Typography>
                    </div>
                  </div>
                </React.Fragment>
              )}
              {/* end subtotal and tip box */}
              <div className={classes.submitButtonContainerMobile}>
                <Button
                  fullWidth
                  className={classNames(classes.submitButton, classes.checkoutButtons, classes.closeTabButton)}
                  color="primary"
                  disabled={submitting || (!values.stripeTipAmount && menuItemCart.location.isOrderingTipsEnabled)}
                  textTransform="none"
                  loading={shouldSubmitMenuItemCart && submitting}
                  onClick={() => {
                    shouldSubmitMenuItemCart = true;
                  }}
                  data-cy="submit_order"
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  <FormattedMessage id="consumer.ordering.close_tab" defaultMessage="Close My Tab" />
                </Button>
                <Button
                  fullWidth
                  color="inherit"
                  textTransform="none"
                  href={`${restaurant.popmenuUrl}/dine-in?location=${menuItemCart.location.slug}#menu`}
                  onClick={closeTab}
                  size="large"
                  type="submit"
                  variant="outlined"
                >
                  <FormattedMessage id="consumer.ordering.keep_ordering" defaultMessage="Keep Ordering" />
                </Button>
              </div>
              {showConfirmModal && (
                <BasicFormConfirmModal
                  closeModal={() => setShowConfirmModal(false)}
                  message={`${t('ordering.dine_in.close_tab_for_x')} ${formatCurrency(getExpectedTotal(menuItemCart, values).total, menuItemCart.location.currency)}?`}
                  onSubmit={() => submitForm({ confirmed: true })}
                  showModal={showConfirmModal}
                  title={t('ordering.dine_in.close_my_tab')}
                />
              )}
            </AHLevelProvider>
          </React.Fragment>
        );
      }}
    </BasicForm>
  );
};

MenuItemCartStripeCloseTab.propTypes = {
  classes: PropTypes.object.isRequired,
  closeTab: PropTypes.func.isRequired,
  menuItemCart: PropTypes.shape({
    id: PropTypes.number,
    location: PropTypes.shape({
      isStripePaymentAvailable: PropTypes.bool,
      stripePublishableKey: PropTypes.string,
    }),
  }).isRequired,
  showSnackbarError: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(
  withIntl,
  withStyles(styles),
  withIntl,
)(MenuItemCartStripeCloseTab);
