import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter, useHistory } from 'react-router-dom';
import { branch, compose, mapProps } from '@shakacode/recompose';
import { useSelector } from 'react-redux';

import { findElement, scrollTo } from '../../../utils/dom';
import { withIntl } from '../../../utils/withIntl';
import { withPopmenuConfig } from '../../../utils/withPopmenuConfig';
import { useWindowSizeContext } from '../../../shared/WindowSizeProvider';
import { useCurrentSession } from '../../../shared/CurrentSessionProvider';
import { toBackgroundStyle } from '../../../utils/styles';
import { getParam } from '../../../utils/urls';
import { classNames, withStyles } from '../../../utils/withStyles';
import { themeShape, withTheme } from '../../../utils/withTheme';
import menuGroupStyles from './styles';

import Announcement from '../../announcements/Announcement';
import DineInCartButton from '../cart/DineInCartButton';
import {
  NextMenuGroupTabPanel,
  NextMenuGroupPanel,
  NextMenuGroupTabs,
  NextMenuGroupCards,
  NextMenuGroupPanelV2,
} from '../Menu.imports-loadable';

const NextMenuGroup = (props) => {
  const {
    classes,
    includeItemLink,
    menus,
    menuColumnCount,
    menuItemCart,
    menuGroupElContainerRef,
    menuTabsLayout,
    mobile,
    renderMobileMenuNavV2,
    showAnnouncement,
    showAddToCartButton,
    theme,
  } = props;
  const history = useHistory();
  const { isMobile } = useWindowSizeContext();
  const currentSession = useCurrentSession();
  const isLoggedIn = currentSession.user;
  const selectedMenuItemMenu = useSelector(state => state.menuItemCart.selectedMenuItem)?.menuId;
  const menuLayout = menuTabsLayout || theme.menuTabsLayout;
  const enabledMenus = menus.filter(m => m.isEnabled);
  const [selectedMenuId, setSelectedMenuId] = useState((menuLayout !== 'card_menu_tabs_layout' && enabledMenus[0] && enabledMenus[0].id) || (renderMobileMenuNavV2 && enabledMenus.some(menu => menu.id === selectedMenuItemMenu) && selectedMenuItemMenu));
  const [trackPageView, setTrackPageView] = useState(false);
  // Also the same height as the Edit Bag Details tag
  const userEmailCheckTagHeight = 52;
  const userMobileProfileTagHeight = 68;

  const containerRef = useRef();

  const selectedMenu = enabledMenus.filter(
    menu => menu.id === selectedMenuId,
  )[0];

  const menuForAnnouncement = selectedMenu || enabledMenus[0];

  const showMobileTabs = isMobile && theme.useMobileMenuTabsLayout;
  // Always show Sidebar and Mobile tabs for menu section navigation, unless there is only 1 menu and 1 section
  const onlyOneMenuSection = !!(
    enabledMenus.length === 1 &&
    enabledMenus[0].enabledSections &&
    enabledMenus[0].enabledSections.length === 1
  );
  const showTabs =
    !onlyOneMenuSection &&
    (menuLayout === 'sidebar_menu_tabs_layout' ||
      showMobileTabs ||
      enabledMenus.length > 1);
  const showSidebarLayout =
    showTabs && menuLayout === 'sidebar_menu_tabs_layout';
  const hasBackground = !!(
    selectedMenu &&
    (selectedMenu.backgroundColor ||
      theme.defaultBackgroundColor ||
      selectedMenu.backgroundImageUrl ||
      theme.defaultBackgroundImageUrl)
  );
  const pendingSubcart = menuItemCart ? menuItemCart.subcarts.find(subcart => subcart.status === 'pending_status') : false;
  // Check for a pending subcart with no selected items while viewing the menu items of a menu in dine-in OO
  const disableDineInCartButton = selectedMenu && pendingSubcart && pendingSubcart.selectedMenuItems.length === 0;

  const setSelectedMenu = useCallback((chosenMenu) => {
    let totalOffset = 0;
    if (renderMobileMenuNavV2) {
      if (isLoggedIn) {
        totalOffset += userMobileProfileTagHeight;
      } else {
        totalOffset += userEmailCheckTagHeight;
      }
    }

    if (!chosenMenu) {
      setSelectedMenuId(null);
      const sectionEl = findElement('.pm-menus-bg');
      if (sectionEl) {
        // 20 is for additional offset when scrolling to each section.
        scrollTo(sectionEl, (totalOffset + 20));
      }
      if (!renderMobileMenuNavV2) {
        window.history.replaceState(null, null, ' ');
      }
      return;
    }

    const { id = null, scroll = false, slug = null } = chosenMenu;
    // Find menu from slug or id
    let menu;
    if (slug && slug.length > 0) {
      menu = menus.filter(m => m.isEnabled && m.slug === slug)[0];
    } else if (id) {
      menu = menus.filter(m => m.isEnabled && m.id === id)[0];
    }
    if (!menu || menu.id === selectedMenuId) {
      return;
    }

    // Track GA page view on tab changes
    setTrackPageView(true);

    // Update state and scroll to top of menu container
    setSelectedMenuId(menu.id);

    // Update history only if menuItemCart is not being set
    if (!menuItemCart) {
      window.history.replaceState(null, null, `#menu=${menu.slug}`);
    }

    // Scroll to the top of menu section
    if (menuItemCart && menuItemCart.cartType === 'dine_in_cart_type') {
      window.scrollTo({
        behavior: 'smooth',
        left: 0,
        top: 0,
      });
    }

    // Scroll to top of menu container if user has scrolled further or is at top of page
    if (scroll && containerRef.current) {
      scrollTo(containerRef.current, 32);
    }
  }, [isLoggedIn, menuItemCart, menus, renderMobileMenuNavV2, selectedMenuId]);

  useEffect(() => {
    // If mobileMenuNavV2 is active and only one enabled menu exists
    if (renderMobileMenuNavV2) {
      const enabledMenusWithSections = enabledMenus.filter(m => m.enabledSections);
      // Set the menu state to that menu if items are available to order in that menu
      if (enabledMenusWithSections.length === 1) {
        setSelectedMenuId(enabledMenus[0].id);
      }
    }
  }, [enabledMenus, renderMobileMenuNavV2]);

  // Listen for forward/backward navigation to change selected menu
  useEffect(() => {
    if (history) {
      const unlisten = history.listen(() => {
        setSelectedMenu({ scroll: true, slug: getParam('menu') });
      });
      return unlisten;
    }
    return () => {};
  }, [history, setSelectedMenu]);

  // Default to first menu if an unavailable menu is somehow selected
  useEffect(() => {
    if (
      menus
        .map(menu => menu.id)
        .indexOf(selectedMenuId) === -1 &&
      menuLayout !== 'card_menu_tabs_layout'
    ) {
      setSelectedMenu({
        id: menus.filter(m => m.isEnabled)[0]?.id,
      });
    }
  });

  // Set initial menu selection based on menu query string
  useEffect(() => {
    const slug = getParam('menu');
    if (slug) {
      setSelectedMenu({ scroll: true, slug });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (menuLayout === 'card_menu_tabs_layout') {
    const viewMyTabButtonText = selectedMenu ? props.t('ordering.dine_in.view_order') : props.t('ordering.dine_in.view_my_tab');
    return (
      <React.Fragment>
        {showAnnouncement && (
          <Announcement menuId={menuForAnnouncement?.id} locationId={menuForAnnouncement?.locationId} />
        )}
        {enabledMenus.length > 0 && (
          <div
            ref={menuGroupElContainerRef || null}
            className={classNames(
              'pm-menus-bg',
              hasBackground ? 'has-background' : null,
              showSidebarLayout ? classes.sidebarGroupContainer : null,
            )}
            style={
              { ...(selectedMenu &&
                toBackgroundStyle({
                  backgroundColor:
                    selectedMenu.backgroundColor || theme.defaultBackgroundColor,
                  backgroundEffect:
                    selectedMenu.backgroundEffect ||
                    theme.defaultBackgroundEffect,
                  backgroundImageUrl:
                    selectedMenu.backgroundImageUrl ||
                    theme.defaultBackgroundImageUrl,
                })),
              padding: 0 }
            }
          >
            {!selectedMenu ? (
              <NextMenuGroupCards
                classes={classes}
                isOO={showAddToCartButton}
                orderingDineInIntroText={menuItemCart?.location?.orderingDineInIntroText}
                menus={enabledMenus}
                menuTabsLayout={menuLayout}
                renderMobileMenuNavV2={renderMobileMenuNavV2}
                selectedMenuId={selectedMenu && selectedMenu.id}
                setSelectedMenu={setSelectedMenu}
                selectedMenu={selectedMenu}
                theme={theme}
                t={props.t}
              />
            ) : renderMobileMenuNavV2 ? (
              <div className={classNames('pm-menus-wrap')}>
                <NextMenuGroupPanelV2
                  classes={classes}
                  includeItemLink={includeItemLink}
                  isLoggedIn={isLoggedIn}
                  isSingleMenu={enabledMenus.filter(menu => menu.enabledSections).length === 1}
                  menuColumnCount={menuColumnCount}
                  menuItemCart={menuItemCart}
                  selectedMenu={selectedMenu}
                  setSelectedMenu={setSelectedMenu}
                  showMobileTabs={showMobileTabs}
                  showAddToCartButton={showAddToCartButton}
                  theme={theme}
                  trackPageView={trackPageView}
                />
              </div>
            ) : (
              <div className={classNames('pm-menus-wrap')}>
                <NextMenuGroupPanel
                  classes={classes}
                  includeItemLink={includeItemLink}
                  menuColumnCount={menuColumnCount}
                  menuItemCart={menuItemCart}
                  menus={enabledMenus}
                  setSelectedMenu={setSelectedMenu}
                  selectedMenuId={selectedMenu && selectedMenu.id}
                  showMobileTabs={showMobileTabs}
                  showAddToCartButton={showAddToCartButton}
                  theme={theme}
                  trackPageView={trackPageView}
                />
              </div>
            )}
            {showAddToCartButton && !renderMobileMenuNavV2 && (
              <div className={classNames(menuItemCart.lastSubcartSubmittedAt || selectedMenu ? classes.viewMyTabButtonContainer : null)}>
                <DineInCartButton
                  disabled={disableDineInCartButton}
                  isMenuSelected={!!selectedMenu}
                  theme={theme}
                  viewMyTabButton
                  viewMyTabButtonText={viewMyTabButtonText}
                />
              </div>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      {showAnnouncement && (
        <Announcement menuId={selectedMenu?.id} locationId={selectedMenu?.locationId} />
      )}
      {enabledMenus.length > 0 && (
        <div
          ref={menuGroupElContainerRef || null}
          className={classNames(
            'pm-menus-bg',
            hasBackground ? 'has-background' : null,
            showSidebarLayout ? classes.sidebarGroupContainer : null,
          )}
          style={
            selectedMenu &&
            toBackgroundStyle({
              backgroundColor:
                selectedMenu.backgroundColor || theme.defaultBackgroundColor,
              backgroundEffect:
                selectedMenu.backgroundEffect ||
                theme.defaultBackgroundEffect,
              backgroundImageUrl:
                selectedMenu.backgroundImageUrl ||
                theme.defaultBackgroundImageUrl,
            })
          }
        >
          {showTabs && (
            <NextMenuGroupTabs
              classes={classes}
              includeItemLink={includeItemLink}
              isOrderingAvailable={showAddToCartButton}
              menus={enabledMenus}
              menuTabsLayout={menuLayout}
              selectedMenuId={selectedMenu && selectedMenu.id}
              setSelectedMenu={setSelectedMenu}
              selectedMenu={selectedMenu}
              showMobileTabs={showMobileTabs}
              mobile={mobile}
            />
          )}
          <div
            className={classNames(
              'pm-menus-wrap',
              showSidebarLayout ? classes.sidebarMenusContainer : null,
            )}
            ref={containerRef}
          >
            <NextMenuGroupTabPanel
              includeItemLink={includeItemLink}
              menuColumnCount={menuColumnCount}
              menuItemCart={menuItemCart}
              menus={enabledMenus}
              selectedMenuId={selectedMenu && selectedMenu.id}
              showMobileTabs={showMobileTabs}
              showAddToCartButton={showAddToCartButton}
              showTabs={showTabs}
              trackPageView={trackPageView}
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

NextMenuGroup.defaultProps = {
  history: null,
  includeItemLink: true,
  menuColumnCount: null,
  menuGroupElContainerRef: null,
  menuItemCart: null,
  menuTabsLayout: null,
  showAddToCartButton: false,
  showAnnouncement: true,
};

NextMenuGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.shape({
    listen: PropTypes.func,
  }),
  includeItemLink: PropTypes.bool,
  includeRouter: PropTypes.bool.isRequired,
  menuColumnCount: PropTypes.number,
  menuGroupElContainerRef: PropTypes.object,
  menuItemCart: PropTypes.object,
  menus: PropTypes.arrayOf(
    PropTypes.shape({
      backgroundColor: PropTypes.string,
      backgroundEffect: PropTypes.string,
      backgroundImageUrl: PropTypes.string,
      disclaimer: PropTypes.string,
      id: PropTypes.number,
      isEnabled: PropTypes.bool,
      name: PropTypes.string,
      photoUrl: PropTypes.string,
      slug: PropTypes.string,
    }),
  ).isRequired,
  menuTabsLayout: PropTypes.string,
  showAddToCartButton: PropTypes.bool,
  showAnnouncement: PropTypes.bool,
  t: PropTypes.func.isRequired,
  theme: themeShape.isRequired,
};

export default compose(
  withStyles(menuGroupStyles),
  withIntl,
  withTheme,
  // Conditionally include react-router HOC to support embeds
  withPopmenuConfig,
  mapProps(({ popmenuConfig, ...props }) => ({
    ...props,
    includeRouter: !popmenuConfig.widget,
  })),
  branch(props => props.includeRouter, withRouter),
  mapProps(({ location, match, staticContext, ...props }) => ({
    ...props,
  })),
)(NextMenuGroup);
