import React from 'react';
import PropTypes from 'prop-types';
import { compose } from '@shakacode/recompose';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@popmenu/common-ui';
import { classNames, withStyles } from '../../../../../utils/withStyles';
import menuItemCartFormStyles from '../styles';
import { AH } from '../../../../shared/AccessibleHeading';

const PaymentMethod = ({ classes, menuItemCart, isOnlinePaymentAvailable, isShownForPickup, children }) => (
  !!(isShownForPickup || isOnlinePaymentAvailable || menuItemCart.location.orderingPaymentMessage) && (
    <section className={classes.sectionContainer}>
      <AH
        typography
        className={
          classNames(classes.sectionHeading, classes.sectionHeadingForm,
            'pm-cart-checkout-form-heading-payment-method')
        }
        component="h3"
        variant="h6"
      >
        <FormattedMessage
          id="consumer.ordering.payment_method"
          defaultMessage="Payment Method"
        />
      </AH>
      <Typography
        className={classNames(classes.formInfoSubtitle, classes.sectionHeadingSpacing)}
      >
        {!menuItemCart.canBalanceBeFullyPaidByThirdPartyGiftCard && (
        <FormattedMessage
          id="forms.all_fields_required"
          defaultMessage="*All Fields Required"
        />
        )}
      </Typography>
      <div className={classes.paymentContainer}>
        {menuItemCart.location.orderingPaymentMessage && (
          <Typography className={classes.paymentMethodDisclaimer}>
            {menuItemCart.location.orderingPaymentMessage}
          </Typography>
        )}
        {children(isOnlinePaymentAvailable)}
      </div>
    </section>
  )
);

PaymentMethod.propTypes = {
  children: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  isOnlinePaymentAvailable: PropTypes.bool.isRequired,
  isShownForPickup: PropTypes.bool,
  menuItemCart: PropTypes.shape({
    id: PropTypes.number,
    location: PropTypes.shape({
      orderingPaymentMessage: PropTypes.string,
      squareLocationId: PropTypes.string,
    }),
  }).isRequired,
};

PaymentMethod.defaultProps = {
  isShownForPickup: false,
};

export default compose(
  withStyles(menuItemCartFormStyles),
)(PaymentMethod);
