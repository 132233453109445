import { dangerouslyExecuteScripts } from './dom';

// Concatenate conversion scripts and append to document body
export const appendConversionScripts = ({ customScripts, googleAdsConversionActionScripts }) => {
  try {
    const followerScripts = (googleAdsConversionActionScripts || []).filter(script => script.name.toLowerCase() === 'follower');
    if (customScripts.length === 0 && followerScripts.length === 0) {
      return;
    }
    let scripts = customScripts.filter(s => s.scriptType === 'conversion' && s.content).map(s => s.content).join('\n');
    const container = document.createElement('div');
    if (followerScripts.length > 0) {
      scripts += `\n${followerScripts.map(script => script.tag).join('\n')}`;
    }
    container.innerHTML = scripts;
    document.body.appendChild(container);
    dangerouslyExecuteScripts(container, 'SignUpForm');
  } catch (err) {
    console.warn(`[POPMENU] Error executing conversion scripts: ${err.message}`);
  }
};

export const appendOnlineConversionScripts = ({ customScripts, expectedCartTotal, googleAdsConversionActionScripts }) => {
  const orderScripts = (googleAdsConversionActionScripts || []).filter(script => script.name.toLowerCase() === 'order');
  if (customScripts.length === 0 && orderScripts.length === 0) {
    return;
  }
  try {
    let scripts = customScripts.filter(s => s.scriptType === 'order_conversion' && s.content).map(s => s.content).join('\n');
    scripts = scripts.replace('expected_cart_total', expectedCartTotal);
    const container = document.createElement('div');
    if (orderScripts.length > 0) {
      scripts += `\n${orderScripts.map(script => script.tag).join('\n')}`;
    }
    container.innerHTML = scripts;
    document.body.appendChild(container);
    dangerouslyExecuteScripts(container, 'SubmitMenuItemCartForm');
  } catch (err) {
    console.warn(`[POPMENU] Error executing conversion scripts: ${err.message}`);
  }
};
