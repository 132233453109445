// See docs/loadable-components.md for details regarding **.imports-X.** file extension & duplicate file structure.
import React from 'react';

import loadable from '@loadable/component';
import Loading from '../../shared/Loading';
import retry from '../../utils/retry';

export const NextMenuGroupTabPanel = loadable(() => retry(() => import('./NextMenuGroupTabPanel')), { fallback: <Loading size="fullscreen-align-top" /> });
export const NextMenuGroupPanel = loadable(() => retry(() => import('./NextMenuGroupPanel')), { fallback: <Loading size="fullscreen-align-top" /> });
export const NextMenuGroupTabs = loadable(() => retry(() => import('./NextMenuGroupTabs')));
export const NextMenuGroupCards = loadable(() => retry(() => import('./NextMenuGroupCards')));
export const NextMenuGroupPanelV2 = loadable(() => retry(() => import('./NextMenuGroupPanelV2')), { fallback: <Loading size="fullscreen-align-top" /> });
