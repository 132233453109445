import React, { useState } from 'react';
import { useFormContext } from 'react-form';
import PropTypes from 'prop-types';

import { Box, Button, Typography } from '@popmenu/common-ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { InputAdornment, makeStyles } from '@material-ui/core';
import { notBlankValidator } from '../../../../../utils/forms';

import { TextFieldGroup } from '../../../../../admin/shared/forms/BasicForm';
import { currencySymbol, formatCurrency } from '../../../../../utils/currency';
import { preventNegative } from '../../../../../utils/events';
import { round } from '../../../../../utils/numbers';

import { classNames } from '../../../../../utils/withStyles';
import menuItemCartFormStyles from '../styles';

const DEFAULT_TIP_OPTION = 20;

const TipOptions = (props) => {
  const { defaultTipOption, menuItemCart, tipAmount, tipField, tipOptions } = props;
  const [showTipWarningText, setShowTipWarningText] = useState(true);
  const intl = useIntl();
  const useStyles = makeStyles(menuItemCartFormStyles);
  const classes = useStyles(props);

  const { setFieldValue } = useFormContext();
  const validDefaultTipOptions = () => {
    const validOptions = [...tipOptions, null];
    const isValid = validOptions.includes(defaultTipOption);
    return isValid ? defaultTipOption : DEFAULT_TIP_OPTION;
  };

  const [selectedTip, setSelectedTip] = useState(validDefaultTipOptions());

  // handle tip percent option selection
  const handleTipPercent = (tipOption) => {
    setShowTipWarningText(false);
    const percentValue = round(menuItemCart.subtotal * (tipOption * 0.01), 2).toFixed(2);
    setFieldValue(tipField, percentValue);
    setSelectedTip(tipOption);
  };
  // handle custom tip option selection
  const handleCustomTip = () => {
    setShowTipWarningText(false);
    if (defaultTipOption === null) {
      setFieldValue(tipField, 0);
    } else {
      setFieldValue(tipField, tipAmount);
    }
    setSelectedTip(null);
  };
  return (
    <React.Fragment>
      <div className={classes.tipBox}>
        {tipOptions.map(tipOption => ((
          <Button
            className={classNames(selectedTip === tipOption ? classes.defaultTipButton : classes.tipPercentButton)}
            data-cy={selectedTip === tipOption ? `selected_tip_option_${tipOption}` : `tip_option_${tipOption}`}
            size="large"
            onClick={() => handleTipPercent(tipOption)}
          >
            <Box display="flex" flexDirection="column">
              <Typography weight="semi-bold"><FormattedMessage id="order_summary.tip_percent" defaultMessage="{tipOption}%" values={{ tipOption }} /></Typography>
              <Typography>{formatCurrency(menuItemCart.subtotal * (tipOption * 0.01), menuItemCart.location.currency, { showDecimals: true, showSymbol: true })}</Typography>
            </Box>
          </Button>
        )))}
        <Button
          data-cy="tip_option_custom"
          className={classNames(selectedTip === null ? classes.defaultTipButton : classes.tipPercentButton)}
          color="default"
          onClick={() => handleCustomTip()}
          textTransform="capitalize"
        >
          <Box display="flex" flexDirection="column">
            <FormattedMessage id="order_summary.custom_tip" defaultMessage="<span>Custom</span> <span>Tip</span>" values={{ span: chunks => <Typography component="span">{chunks}</Typography> }} />
          </Box>
        </Button>
      </div>
      {selectedTip === null && (
        <TextFieldGroup
          className={classes.tipField}
          aria-label="Custom Tip"
          field={tipField}
          helperText={intl.formatMessage({ defaultMessage: `A tip of ${currencySymbol(menuItemCart.location.currency)}0 or more must be entered`, id: 'order_summary.customTip.helperText' })}
          placeholder={intl.formatMessage({ defaultMessage: 'Custom Tip', id: 'order_summary.customTip.placeholder' })}
          step="any"
          type="number"
          onKeyPress={preventNegative}
          InputProps={{
            inputProps: {
              min: 0,
              step: '0.01',
            },
            startAdornment: <InputAdornment position="start">{currencySymbol(menuItemCart.location.currency)}</InputAdornment>,
          }}
          validate={notBlankValidator}
        />
      )}
      {showTipWarningText && menuItemCart.cartType === 'dine_in_cart_type' && (
        <Typography className={classes.tipWarningText}>
          <FormattedMessage id="consumer.ordering.tip_instructions" defaultMessage="Please select a tip amount to close your tab" />
        </Typography>
      )}
    </React.Fragment>
  );
};

TipOptions.propTypes = {
  defaultTipOption: PropTypes.number.isRequired,
  menuItemCart: PropTypes.object.isRequired,
  tipAmount: PropTypes.number.isRequired,
  tipField: PropTypes.string.isRequired,
  tipOptions: PropTypes.array.isRequired,
};

export default TipOptions;
