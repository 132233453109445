import React, { useState } from 'react';
import { Icon, Button, Tooltip, Typography } from '@popmenu/common-ui';
import { ChevronDown, ChevronUp, Edit, ShoppingBag } from '@popmenu/web-icons';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { compose } from '@shakacode/recompose';
import { Link } from 'react-router-dom';
import { Accordion, AccordionSummary, AccordionDetails, Box } from '@material-ui/core';

import { formatCurrency, nl2br } from '../../../utils/utils';
import { withIntl } from '../../../utils/withIntl';
import { useMenuItemCartValidation } from '../../shared/hooks/useMenuItemCartValidation';
import { AH, AHLevelProvider } from '../../shared/AccessibleHeading';
import { openEditSelectedItemModal } from '../../../shared/DishActions';
import { handleNotFound } from './MenuItemCartHelpers';
import destroyMenuItemCartSelectedMenuItemMutation from '../../../libs/gql/mutations/menu_item_carts/destroyMenuItemCartSelectedMenuItemMutation.gql';
import MenuItemCartCloseTab from './MenuItemCartCloseTab';
import DestroyControl from '../../../admin/shared/DestroyControl';

const DineInCartButtonSummary = ({ closeShowCartModal, classes, customPageUrl, menuItemCart, t }) => {
  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState(['pendingItems']);
  const [showCloseTab, setShowCloseTab] = useState(false);

  const handleSetExpanded = (accordionName) => {
    if (expanded.includes(accordionName)) {
      const index = expanded.indexOf(accordionName);
      const newExpanded = [...expanded];
      newExpanded.splice(index, 1);
      setExpanded([...newExpanded]);
    } else {
      setExpanded([...expanded, accordionName]);
    }
  };

  const submittedSubcarts = menuItemCart.subcarts.length > 0 ? menuItemCart.subcarts.filter(subcart => subcart.status !== 'pending_status') : [];
  let submittedItemsCount = 0;
  submittedSubcarts.forEach((subcart) => {
    subcart.selectedMenuItems.forEach((menuItem) => {
      submittedItemsCount += menuItem.quantity || 0;
    });
  });
  const pendingSubcart = menuItemCart.subcarts.length > 0 ? menuItemCart.subcarts.find(subcart => subcart.status === 'pending_status') : null;
  let pendingItemsCount = 0;
  if (pendingSubcart) {
    pendingSubcart.selectedMenuItems.forEach((menuItem) => {
      pendingItemsCount += menuItem.quantity || 0;
    });
  }

  const onCheckoutButtonClicked = () => {
    if (pendingSubcart && pendingSubcart.selectedMenuItems.length > 0) {
      closeShowCartModal();
      window.location.href = `${customPageUrl}?location=${menuItemCart.location.slug}#checkout`;
    } else {
      setShowCloseTab(true);
    }
  };

  const [isMenuItemCartValidating, checkoutButtonClicked] = useMenuItemCartValidation({
    menuItemCartId: menuItemCart.id,
    navigateToCheckoutPage: onCheckoutButtonClicked,
  });

  if (showCloseTab) {
    return (
      <MenuItemCartCloseTab
        menuItemCart={menuItemCart}
        closeTab={() => setShowCloseTab(false)}
        customPageUrl={customPageUrl}
      />
    );
  }

  const currency = menuItemCart?.location?.currency;

  return (
    <div data-cy="dine-in-summary-modal" className={classes.dineInSummaryWrapper}>
      <div>
        <AH typography align="center" variant="h6" className={classes.dineInSummaryHeader}>
          <Box fontWeight="fontWeightBold">
            <Icon icon={ShoppingBag} />
            <FormattedMessage id="consumer.ordering.order_title" defaultMessage="My Order" />
          </Box>
        </AH>
        <AHLevelProvider>
          {/* My Tab current order summary */}
          <Accordion expanded={expanded.includes('submittedItems')} onChange={() => handleSetExpanded('submittedItems')} className={classes.accordion}>
            <AccordionSummary className={classes.accordionSummary}>
              <div className={classes.summaryTextWrapper}>
                <AH typography variant="h6">
                  <Box fontWeight="fontWeightBold">
                    {t(`consumer.ordering.${menuItemCart.cartType}.cart_summary_heading`)}
                    <FormattedMessage
                      id="consumer.ordering.submitted_item_count"
                      defaultMessage=" ({submittedItemCount} {submittedItemCount, plural,
                        one {item}
                        other {items}
                      })"
                      values={{
                        submittedItemCount: submittedItemsCount,
                      }}
                    />
                  </Box>
                </AH>
                <Icon className={classes.accordionExpandIcon} icon={expanded.includes('submittedItems') ? ChevronUp : ChevronDown} />
              </div>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              {menuItemCart.selectedMenuItems.length === 0 && (
              <React.Fragment>
                <Icon className={classes.emptyCart} icon={ShoppingBag} />
                <Typography align="center" className={classes.cartSummaryDescription}>
                  <FormattedMessage id="consumer.ordering.cart_empty" defaultMessage="Your tab is empty." />
                </Typography>
                <Typography align="center" className={classes.cartSummaryDescription}>
                  <FormattedMessage id="consumer.ordering.cart_empty_add_items" defaultMessage="Place a new order to add something to your tab." />
                </Typography>
              </React.Fragment>
              )}
              {submittedSubcarts.map(subcart => (
                subcart.selectedMenuItems.map(selectedMenuItem => (
                  <React.Fragment key={selectedMenuItem.id}>
                    <Typography className={classes.menuItemName}>
                      <span className={classes.menuItemPrice} style={{ textDecoration: selectedMenuItem.isVoid ? 'line-through' : 'none' }}>
                        <FormattedMessage
                          id="consumer.ordering.price_x_quantity"
                          defaultMessage="{price} x{quantity}"
                          values={{
                            price: formatCurrency(selectedMenuItem.price, currency, { showDecimals: true, showSymbol: true }),
                            quantity: selectedMenuItem.quantity,
                          }}
                        />
                      </span>
                      {selectedMenuItem.menuItemName}
                      {selectedMenuItem.summaryExtended && (
                      <React.Fragment>
                        <br />
                        {nl2br(selectedMenuItem.summaryExtended)}
                      </React.Fragment>
                      )}
                    </Typography>
                  </React.Fragment>
                ))))}
              <Typography align="right" className={classes.tabSubtotalContainer}>
                <span className={classes.tabSubtotal}>
                  <FormattedMessage id="consumer.ordering.tab_subtotal" defaultMessage="Tab Subtotal:" />
                </span>
                <span className={classes.menuItemPrice}>
                  <FormattedMessage
                    id="consumer.ordering.price"
                    defaultMessage="{price}"
                    values={{
                      price: formatCurrency(menuItemCart.subtotal - (pendingSubcart?.selectedMenuItems.map(item => item.price * item.quantity).reduce((accumulator, currentValue) => accumulator + currentValue, 0) || 0), currency, { showDecimals: true, showSymbol: true }),
                    }}
                  />
                </span>
              </Typography>
            </AccordionDetails>
          </Accordion>
          {/* My Tab new order summary  */}
          <Accordion expanded={expanded.includes('pendingItems')} onChange={() => handleSetExpanded('pendingItems')} className={classes.accordion}>
            <AccordionSummary className={classes.accordionSummary}>
              <div className={classes.summaryTextWrapper}>
                <AH typography gutterBottom variant="h6">
                  <Box fontWeight="fontWeightBold">
                    {t(`consumer.ordering.${menuItemCart.cartType}.pending_cart_summary_heading`)}
                    <FormattedMessage
                      id="consumer.ordering.pending_item_count"
                      defaultMessage=" ({pendingItemCount} {pendingItemCount, plural,
                        one {item}
                        other {items}
                      })"
                      values={{
                        pendingItemCount: pendingItemsCount,
                      }}
                    />
                  </Box>
                </AH>
                <Icon className={classes.accordionExpandIcon} icon={expanded.includes('pendingItems') ? ChevronUp : ChevronDown} />
              </div>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              {(!pendingSubcart || pendingSubcart.selectedMenuItems.length === 0) && (
              <React.Fragment>
                <Icon className={classes.emptyCart} icon={ShoppingBag} />
                <Typography align="center" className={classes.cartSummaryDescription}>
                  <FormattedMessage id="consumer.ordering.cart_empty" defaultMessage="You have no new items to order." />
                </Typography>
                <Typography align="center" className={classes.cartSummaryDescription}>
                  <FormattedMessage id="consumer.ordering.cart_empty_add_items" defaultMessage="Please add items from the menu." />
                </Typography>
              </React.Fragment>
              )}
              {pendingSubcart && pendingSubcart.selectedMenuItems.length > 0 && pendingSubcart.selectedMenuItems.map(selectedMenuItem => (
                <React.Fragment key={selectedMenuItem.id}>
                  <Typography className={classes.menuItemName}>
                    {!!selectedMenuItem.subtotal && (
                    <span className={classes.menuItemPrice}>
                      <FormattedMessage
                        id="consumer.ordering.price_x_quantity"
                        defaultMessage="{price} x{quantity}"
                        values={{
                          price: formatCurrency(selectedMenuItem.price, currency, { showDecimals: true, showSymbol: true }),
                          quantity: selectedMenuItem.quantity,
                        }}
                      />
                    </span>
                    )}
                    {selectedMenuItem.menuItemName}
                    {selectedMenuItem.summaryExtended && (
                    <React.Fragment>
                      <br />
                        {nl2br(selectedMenuItem.summaryExtended)}
                    </React.Fragment>
                    )}
                  </Typography>
                  <div>
                    <Tooltip title={t('consumer.ordering.edit_item_tooltip')}>
                      <Button
                        className={classes.infoColorText}
                        startIcon={<Icon icon={Edit} />}
                        textTransform="none"
                        onClick={() => dispatch(openEditSelectedItemModal(selectedMenuItem.id))}
                        size="small"
                        variant="text"
                      >
                        <FormattedMessage id="consumer.ordering.edit_item" defaultMessage="Edit" />
                      </Button>
                    </Tooltip>
                    <DestroyControl
                      ButtonProps={{
                        className: classes.infoColorText,
                        size: 'small',
                        textTransform: 'none',
                        variant: 'text',
                      }}
                      onError={err => handleNotFound(err, dispatch)}
                      confirmMessage="Are you sure you want to remove this item from your tab?"
                      mutation={destroyMenuItemCartSelectedMenuItemMutation}
                      text={t('consumer.ordering.remove_item')}
                      variables={{
                        selectedMenuItemId: selectedMenuItem.id,
                      }}
                    />
                  </div>
                </React.Fragment>
              ))}
              <Typography align="right" className={classes.newTabSubtotalContainer}>
                <span className={classes.tabSubtotal}>
                  <FormattedMessage id="consumer.ordering.new_order_subcart" defaultMessage="New Order Subtotal:" />
                </span>
                <span className={classes.menuItemPrice}>
                  <FormattedMessage
                    id="consumer.ordering.subcart_price"
                    defaultMessage="{price}"
                    values={{
                      price: formatCurrency(pendingSubcart?.selectedMenuItems.map(item => item.price * item.quantity).reduce((accumulator, currentValue) => accumulator + currentValue, 0) || 0, currency, { showDecimals: true, showSymbol: true }),
                    }}
                  />
                </span>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </AHLevelProvider>
      </div>
      <div className={classes.dineInButtonContainer}>
        <Button
          fullWidth
          className={classes.addToCartButton}
          color="primary"
          loading={isMenuItemCartValidating}
          disabled={menuItemCart.isSubmitted || menuItemCart.selectedMenuItems.length === 0 || isMenuItemCartValidating}
          textTransform="none"
          onClick={checkoutButtonClicked}
          size="large"
          style={{ justifyContent: 'center' }}
          variant="contained"
        >
          <div>
            <FormattedMessage id="consumer.ordering.place_order" defaultMessage={pendingSubcart && pendingSubcart.selectedMenuItems.length > 0 ? 'Continue' : 'Close Tab'} />
          </div>
        </Button>
        <Button
          fullWidth
          color="inherit"
          component={Link}
          textTransform="none"
          onClick={closeShowCartModal}
          size="large"
          to={menuItemCart.cartType === 'dine_in_cart_type' ? `${customPageUrl}?location=${menuItemCart.location.slug}#menu` : `${customPageUrl}#menu?location=${menuItemCart.location.slug}`}
          type="submit"
          variant="outlined"
          style={{ backgroundColor: 'white', display: 'inline-block', textAlign: 'center' }}
        >
          <FormattedMessage id="consumer.ordering.add_more_dishes" defaultMessage="Add more dishes" />
        </Button>
      </div>
    </div>
  );
};

DineInCartButtonSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  closeShowCartModal: PropTypes.func.isRequired,
  customPageUrl: PropTypes.string.isRequired,
  menuItemCart: PropTypes.object.isRequired,
};

export default compose(
  withIntl,
)(DineInCartButtonSummary);
