import React, { useState } from 'react';
import { compose } from '@shakacode/recompose';
import PropTypes from 'prop-types';
import { useMutation } from '~/lazy_apollo/client';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Button, Icon, Typography } from '@popmenu/common-ui';
import { CheckCircle, Help, Lock } from '@popmenu/web-icons';
import { withIntl } from '../../../../utils/withIntl';
import { formatCurrency, tabName, toFloat } from '../../../../utils/utils';
import { withStyles } from '../../../../utils/withStyles';
import styles from './styles';
import CodeInputBox from '../../../online_ordering/CodeInputBox';
import closeMenuItemCartTabMutation from '../../../../libs/gql/mutations/menu_item_carts/closeMenuItemCartTabMutation.gql';
import TipOptions from '../SubmitMenuItemCartForm/MenuItemCartForm/TipOptions';
import BasicForm, { CheckBoxGroup } from '../../../../admin/shared/forms/BasicForm';
import { getExpectedTotal, getFeeAmount, getTipFieldName, TIP_OPTIONS } from '../SubmitMenuItemCartForm/utils/common';
import BasicFormConfirmModal from '../../../../admin/shared/forms/BasicForm/BasicFormConfirmModal';
import { appendOnlineConversionScripts } from '../../../../utils/conversion';
import { trackFacebookEvent } from '../../../../utils/fbq';

import VipSVG from '../../../../assets/svg/vip.svg';
import FollowDisclaimer from '../../../../shared/sessions/FollowDisclaimer/FollowDisclaimer';
import { resetMenuItemCartData } from '../../../../shared/MenuItemCartActions';
import { handleNotFound } from '../MenuItemCartHelpers';
import { AccordionDiv } from '../SubmitMenuItemCartHelpers';
import MarketingOptInRequest from '../../../../shared/sessions/MarketingOptInRequest';
import { AH, AHLevelProvider } from '../../../shared/AccessibleHeading';

let shouldSubmitMenuItemCart = false;

const MenuItemCartCloseTabForm = ({ closeTab, classes, currentUser, customPageUrl, menuItemCart, restaurant, showSnackbarError, t }) => {
  const dispatch = useDispatch();
  const [closeMenuItemCartTab] = useMutation(closeMenuItemCartTabMutation);
  const [submitting, setSubmitting] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { isOrderingDineInHidePaymentStepEnabled } = menuItemCart.location;

  return (
    <BasicForm
      style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, minHeight: '100vh' }}
      defaultValues={{
        email: menuItemCart.email,
        paymentMethod: menuItemCart.paymentMethod,
        shouldCreateFollower: !restaurant.isMarketingOptInEnabled && !currentUser && menuItemCart.email,
        subscribeEmail: true,
        subscribeSms: true,
      }}
      onSubmit={({ confirmed, shouldCreateFollower, subscribeEmail, subscribeSms, ...variables }) => {
        if (submitting) {
          return;
        }

        // Display confirmation modal on submit
        if (shouldSubmitMenuItemCart && !confirmed) {
          setShowConfirmModal(true);
          return;
        }
        setSubmitting(true);

        const expectedTotal = getExpectedTotal(menuItemCart, variables).total;
        closeMenuItemCartTab({
          variables: {
            expectedTotal,
            isConsumerClosingTab: true,
            menuItemCartId: menuItemCart.id,
            menuItemCartInput: {
              ...variables,
              heartlandTipAmount: toFloat(variables.heartlandTipAmount),
              squareTipAmount: toFloat(variables.squareTipAmount),
              stripeTipAmount: toFloat(variables.stripeTipAmount),
            },
            shouldCreateFollower: !!shouldCreateFollower,
            submitMenuItemCart: shouldSubmitMenuItemCart,
            userInput: {
              isSmsUnsubscribed: !subscribeSms,
              isUnsubscribed: !subscribeEmail,
            },
          },
        }).then(() => {
          setSubmitting(false);
          if (shouldSubmitMenuItemCart) {
            appendOnlineConversionScripts({
              customScripts: restaurant.customConversionScripts,
              expectedCartTotal: expectedTotal,
              googleAdsConversionActionScripts: restaurant.googleAdsConversionActionScripts,
            });
            trackFacebookEvent('Purchase', { currency: 'USD', value: expectedTotal });
            resetMenuItemCartData(); // Todo: test with dispatch()
          }
          if (customPageUrl) {
            window.location.href = `${customPageUrl}?location=${menuItemCart.location.slug}#checkout`;
          }
        }).catch((err) => {
          showSnackbarError(err);
          setSubmitting(false);
          handleNotFound(err, dispatch);
        });
      }}
      data-cy={'online_ordering_checkout_form'}
    >
      {({ submitForm, values }) => {
        const feeAmount = getFeeAmount(menuItemCart, values);
        const totals = getExpectedTotal(menuItemCart, values);
        const tipAmount = values[getTipFieldName(menuItemCart)] || 0;

        return (
          <React.Fragment>
            <div className={classes.closeMyTab}>
              <AH typography align="center" variant="h5" className={classes.closeMyTabText}>
                <FormattedMessage id="consumer.ordering.close_tab" defaultMessage="Close My Tab" />
              </AH>
              <Typography align="center">
                {!menuItemCart.location.isOrderingDineInRemoveTableLabelEnabled && (
                  <FormattedMessage
                    id="consumer.ordering.tab_label"
                    defaultMessage="{table_label}: {table} | "
                    values={{
                      table: <span className={classes.tabName}>{menuItemCart.dineInTableNumber}</span>,
                      table_label: menuItemCart.location.orderingDineInTableNumberLabel ? menuItemCart.location.orderingDineInTableNumberLabel : t('models.menu_item_cart.table_label'),
                    }}
                  />
                )}
                <FormattedMessage
                  id="consumer.ordering.table_tab_label"
                  defaultMessage="{tab_label}: {tab}"
                  values={{
                    tab: t('models.menu_item_cart.tab_label'),
                    tab_label: <span className={classes.tabName}>{tabName(menuItemCart.name || 'N/A')}</span>,
                  }}
                />
              </Typography>
            </div>
            <AHLevelProvider>
              {!isOrderingDineInHidePaymentStepEnabled && (
                <div className={classes.paymentDescriptionBox}>
                  <div className={classes.paymentDescriptionHeading}>
                    <AH typography display="inline" variant="h6" className={classes.paymentDescriptionHeadingText}>
                      <FormattedMessage id="consumer.ordering.payment_info_title" defaultMessage="Payment Info" />
                    </AH>
                    <Icon icon={CheckCircle} />
                  </div>
                  <div className={classes.paymentDescriptionText}>
                    <Icon icon={Lock} />
                    <Typography display="inline">
                      {menuItemCart.paymentDescription}
                    </Typography>
                  </div>
                  <div className={classes.greyInfoBox}>
                    <Typography align="center" className={classes.greyInfoBoxText}>
                      <FormattedMessage id="consumer.ordering.stripe_submit_charge_disclaimer" defaultMessage="Your card won't be charged until you close your tab" />
                    </Typography>
                  </div>
                </div>
              )}
              <AccordionDiv
                menuItemCart={menuItemCart}
                classes={classes}
                defaultExpanded={!menuItemCart.email}
              />
              {/* VIP box */}
              {!currentUser && menuItemCart.email && (
                <div className={classes.vipBox}>
                  <div className={classes.vipCheckboxWrapper}>
                    <CheckBoxGroup
                      className={classes.vipCheckboxTitle}
                      field="shouldCreateFollower"
                      title={t('consumer.ordering.become_vip')}
                    />
                    <Icon icon={VipSVG} style={{ height: '100%', width: '30%' }} />
                  </div>
                  <div>
                    {restaurant.isMarketingOptInEnabled && values.shouldCreateFollower && <MarketingOptInRequest isCheckout isDineIn />}
                  </div>
                  <div className={classes.followDisclaimer}>
                    <FollowDisclaimer align="left" />
                  </div>
                </div>
              )}
              {/* end VIP box */}
              {!isOrderingDineInHidePaymentStepEnabled && (
                <CodeInputBox
                  classes={classes}
                  menuItemCart={menuItemCart}
                  restaurant={restaurant}
                />
              )}
              {/* subtotal and tip box */}
              {!!menuItemCart.subtotal && (
                <React.Fragment>
                  <TipOptions defaultTipOption={menuItemCart.location.defaultTipOption} menuItemCart={menuItemCart} tipAmount={tipAmount} tipField={getTipFieldName(menuItemCart)} tipOptions={TIP_OPTIONS} />
                  {menuItemCart.selectedGiftCard && (
                    <div className={classes.giftCardContainer}>
                      <Typography className={classes.menuItemValue}>
                        <span>
                          <FormattedMessage id="consumer.gift_cards.gift_card_title" defaultMessage="Gift Card" />
                        </span>
                        <span>
                          <FormattedMessage
                            id="consumer.gift_cards.gift_card_discount"
                            defaultMessage="-{gift_card_discount}"
                            values={{
                              gift_card_discount: formatCurrency(totals.giftCardDiscountAmount, 'usd', { showDecimals: true, showSymbol: true }),
                            }}
                          />
                        </span>
                      </Typography>
                      <div className={classes.giftCardRemainingBalance}>
                        <span><FormattedMessage id="consumer.gift_cards.remaining_balance" defaultMessage="Remaining Balance" /></span> {formatCurrency(menuItemCart.selectedGiftCard.giftCard.amount - totals.giftCardDiscountAmount, 'usd', { showDecimals: true, showSymbol: true })}
                      </div>
                    </div>
                  )}
                  <div className={classes.totalContainer}>
                    <div className={classes.menuItemValueContainer}>
                      <Typography className={classes.menuItemValue}>
                        <span>
                          <FormattedMessage id="consumer.ordering.subtotal_title" defaultMessage="Subtotal" />
                        </span>
                        <span>
                          {formatCurrency(menuItemCart.subtotal, 'usd', { showDecimals: true, showSymbol: true })}
                        </span>
                      </Typography>
                    </div>
                    <br />
                    {menuItemCart.selectedOrderingOfferCode && (
                      <React.Fragment>
                        <div className={classes.menuItemValueContainer}>
                          <Typography className={classes.menuItemDiscount}>
                            <span>
                              <FormattedMessage id="consumer.ordering.offer_title" defaultMessage="Offer" />
                            </span>
                            <span>
                              <FormattedMessage
                                id="consumer.ordering.offer_discount"
                                defaultMessage="-{offer_discount}"
                                values={{
                                  offer_discount: formatCurrency(menuItemCart.discountAmount || 0, 'usd', { showDecimals: true, showSymbol: true }),
                                }}
                              />
                            </span>
                          </Typography>
                        </div>
                        <br />
                      </React.Fragment>
                    )}
                    {!!feeAmount && (
                      <React.Fragment>
                        <div className={classes.menuItemValueContainer}>
                          <Typography className={classes.menuItemValue}>
                            <span>
                              <FormattedMessage id="consumer.ordering.fees_title" defaultMessage="Fees" />
                              <Icon
                                className={classes.feesIcon}
                                icon={Help}
                                tooltip={t('consumer.ordering.online_ordering_fee')}
                              />
                            </span>
                            <span>
                              {formatCurrency(feeAmount, 'usd', { showDecimals: true, showSymbol: true })}
                            </span>
                          </Typography>
                        </div>
                        <br />
                      </React.Fragment>
                    )}
                    {!!menuItemCart.onlineTaxAmount && (
                      <React.Fragment>
                        <div className={classes.menuItemValueContainer}>
                          <Typography className={classes.menuItemValue}>
                            <span>
                              <FormattedMessage id="consumer.ordering.taxes_title" defaultMessage="Taxes" />
                            </span>
                            <span>
                              {formatCurrency(menuItemCart.onlineTaxAmount, 'usd', { showDecimals: true, showSymbol: true })}
                            </span>
                          </Typography>
                        </div>
                        <br />
                      </React.Fragment>
                    )}
                    {menuItemCart.location.isOrderingTipsEnabled && !isOrderingDineInHidePaymentStepEnabled && (
                      <React.Fragment>
                        <div className={classes.menuItemValueContainer}>
                          <Typography className={classes.menuItemValue}>
                            <span>
                              <FormattedMessage id="consumer.ordering.tip_title" defaultMessage="Tip" />
                            </span>
                            <span>
                              {formatCurrency(toFloat(tipAmount), 'usd', { showDecimals: true, showSymbol: true })}
                            </span>
                          </Typography>
                        </div>
                        <br />
                      </React.Fragment>
                    )}
                    <div className={classes.dashedDivider} />
                    <div className={classes.menuItemValueContainer}>
                      <Typography className={classes.menuItemTotal}>
                        <FormattedMessage
                          id="consumer.ordering.order_total"
                          defaultMessage="Total: {order_total}"
                          values={{
                            order_total: formatCurrency(totals.total, 'usd', { showDecimals: true, showSymbol: true }),
                          }}
                        />
                      </Typography>
                    </div>
                  </div>
                </React.Fragment>
              )}
              {/* end subtotal and tip box */}
              <div className={classes.submitButtonContainerMobile}>
                <Button
                  data-cy="submit_order"
                  fullWidth
                  color="primary"
                  className={classes.closeTabButton}
                  disabled={submitting ||
                    (((values.paymentMethod === 'stripe_payment_method' && !values.stripeTipAmount) ||
                        (values.paymentMethod === 'square_payment_method' && !values.squareTipAmount) ||
                        (values.paymentMethod === 'heartland_payment_method' && !values.heartlandTipAmount)) &&
                      (menuItemCart.location.isOrderingTipsEnabled && !isOrderingDineInHidePaymentStepEnabled))}
                  textTransform="none"
                  loading={shouldSubmitMenuItemCart && submitting}
                  onClick={() => {
                    shouldSubmitMenuItemCart = true;
                  }}
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  <FormattedMessage id="consumer.ordering.close_my_tab" defaultMessage="Close My Tab" />
                </Button>
                <Button
                  fullWidth
                  color="inherit"
                  textTransform="none"
                  href={`${restaurant.popmenuUrl}/dine-in?location=${menuItemCart.location.slug}#menu`}
                  onClick={closeTab}
                  size="large"
                  type="submit"
                  variant="outlined"
                >
                  <FormattedMessage id="consumer.ordering.keep_ordering" defaultMessage="Keep Ordering" />
                </Button>
              </div>
              {showConfirmModal && (
                <BasicFormConfirmModal
                  closeModal={() => setShowConfirmModal(false)}
                  message={`${t('ordering.dine_in.close_tab_for_x')} ${formatCurrency(getExpectedTotal(menuItemCart, values).total, menuItemCart.location.currency)}?`}
                  onSubmit={() => submitForm({ confirmed: true })}
                  showModal={showConfirmModal}
                  title={t('ordering.dine_in.close_my_tab')}
                />
              )}
            </AHLevelProvider>
          </React.Fragment>
        );
      }}
    </BasicForm>
  );
};

MenuItemCartCloseTabForm.defaultProps = {
  customPageUrl: null,
};

MenuItemCartCloseTabForm.propTypes = {
  classes: PropTypes.object.isRequired,
  closeTab: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  customPageUrl: PropTypes.number,
  feeAmount: PropTypes.number.isRequired,
  getExpectedTotal: PropTypes.func.isRequired,
  menuItemCart: PropTypes.shape({
    id: PropTypes.number,
    location: PropTypes.shape({
      isSquarePaymentAvailable: PropTypes.bool,
      isStripePaymentAvailable: PropTypes.bool,
    }),
  }).isRequired,
  restaurant: PropTypes.object.isRequired,
  showSnackbarError: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(
  withIntl,
  withStyles(styles),
)(MenuItemCartCloseTabForm);
