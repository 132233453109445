export default theme => ({
  blueCheck: {
    color: '#0A6AE2',
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
  },
  blueGiftCardBox: {
    '& a': {
      color: '#000',
      fontSize: theme.spacing(1.5),
      textDecoration: 'underline',
    },
    backgroundColor: '#DCF1FA',
    color: '#000',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1),
  },
  boxButtons: {
    marginLeft: 'auto',
  },
  boxText: {
    alignItems: 'center',
    display: 'flex',
  },
  checkoutButtons: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    fontWeight: 'bold',
    textTransform: 'capitalize',
    width: '50%',
  },
  clickableHelperText: {
    color: theme.palette.blue.dark,
    cursor: 'pointer',
    fontSize: 'small',
    marginLeft: theme.spacing(1.5),
    marginTop: theme.spacing(0.1),
    textDecoration: 'underline',
  },
  contactContainer: {
    [theme.breakpoints.down('sm')]: {
      background: 'none',
      borderRadius: 0,
      padding: 0,
    },
    backgroundColor: 'white',
    borderRadius: '4px',
    padding: theme.spacing(4),
  },
  continueButton: {
    marginBottom: theme.spacing(2),
  },
  defaultTipButton: {
    '&:active, &:focus': {
      backgroundColor: theme.consumer.primaryColorOnWhite,
      color: theme.palette.getContrastText(theme.consumer.primaryColorOnWhite),
    },
    '@media (hover: none)': {
      '&:hover': {
        backgroundColor: `${theme.consumer.primaryColorOnWhite} !important`,
        color: theme.palette.getContrastText(theme.consumer.primaryColorOnWhite),
      },
    },
    backgroundColor: theme.consumer.primaryColorOnWhite,
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '8px',
    color: theme.palette.getContrastText(theme.consumer.primaryColorOnWhite),
    minWidth: 80,
  },
  deliveryMessage: {
    marginTop: `${theme.spacing(1)}px`,
  },
  disclaimer: {
    color: 'inherit',
    fontSize: '1em',
    marginTop: theme.spacing(0),
    textAlign: 'left',
  },
  discountedPriceBox: {
    '& span': {
      color: '#717c88',
      fontSize: '14px',
      textDecoration: 'line-through',
    },
    display: 'grid',
  },
  doordashMessage: {
    color: theme.consumer.general.mutedText.color,
    fontSize: '0.85rem',
  },
  doordashMessageContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: theme.spacing(21),
    paddingBottom: theme.spacing(2),
  },
  editCartButton: {
    color: theme.consumer.menuItemCart.summary.link.color,
    fontSize: '1rem',
    textTransform: 'none !important',
  },
  feeDisclaimer: {
    color: theme.consumer.general.mutedText.color,
    fontSize: '0.85rem',
    padding: theme.spacing(1, 0),
    paddingLeft: theme.spacing(1.5),
    textAlign: 'left',
  },
  feesIcon: {
    marginLeft: theme.spacing(0.5),
    position: 'relative',
    top: '2px',
  },
  flex: {
    display: 'flex',
  },
  followDisclaimer: {
    '& button': {
      position: 'relative',
      top: '-1px',
    },
    marginTop: '1px',
  },
  formInfoSubtitle: {
    color: theme.consumer.menuItemCart.formInfoSubtitle.color,
  },
  freeDeliveryNotification: {
    backgroundColor: '#FFFFFF',
    borderRadius: '4px',
    fontSize: '0.8rem',
    fontWeight: theme.typography.fontWeightBold,
    padding: '5px',
    textAlign: 'center',
  },
  giftCardCantCoverTipBox: {
    backgroundColor: '#DCF1FA',
    color: '#CC0000',
    padding: theme.spacing(0, 1),
  },
  giftCardSummaryAmount: {
    color: '#0000FF',
  },
  giftCardSummaryContainer: {
    backgroundColor: 'rgba(100, 100, 100, 0.2)',
    padding: `${theme.spacing(2)}px`,
  },
  giftCardSummaryExtra: {
    color: 'rgba(100, 100, 100, 0.75)',
    fontSize: 'small',
    width: '100%',
  },
  giftCardSummaryTitle: {
    width: '100%',
  },
  greenCheck: {
    color: '#109F00',
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
  },
  greenOfferBox: {
    '& a': {
      color: '#000',
      fontSize: theme.spacing(1.5),
      textDecoration: 'underline',
    },
    backgroundColor: '#E8F7E8',
    color: '#000',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1),
  },
  hiddenSquareLine: {
    marginTop: '-89px',
    visibility: 'hidden',
  },
  locationHours: {
    '& .hours-day': {
      float: 'left',
      marginRight: theme.spacing(1),
    },
    '& .hours-times': {
      textAlign: 'right',
    },
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
    color: 'rgba(0, 0, 0, 0.87)',
    marginBottom: theme.spacing(2),
    maxWidth: '320px',
    padding: theme.spacing(2),
  },
  locationHoursHeading: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(-2),
  },
  locationTimeContainer: {
    '& svg': {
      marginRight: '10px',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(5),
    },
    marginBottom: theme.spacing(6),
  },
  menuItemContainer: {
    marginBottom: theme.spacing(5),
  },
  menuItemSubtotal: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  menuItemTotal: {
    fontWeight: 'bold',
  },
  menuItemTotalDisclaimer: {
    marginBottom: theme.spacing(2),
    opacity: 0.4,
    textAlign: 'right',
  },
  minimumBox: {
    '& svg': {
      fontSize: 'x-large',
      marginRight: '5px',
      verticalAlign: 'middle',
    },
    color: '#FF0000',
    fontSize: '13px',
    margin: '10px',
  },
  noMarginBottom: {
    marginBottom: '0px !important',
  },
  noPadding: {
    padding: '0 0 0 0 !important',
  },
  offerCodeButton: {
    borderRadius: '0px',
  },
  offerIcon: {
    color: '#CC0000',
    marginLeft: theme.spacing(0.5),
    position: 'relative',
    top: '2px',
    transform: 'scaleX(-1)',
  },
  offerTextBox: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '0px',
    },
  },
  orderTypeSummaryText: {
    color: theme.consumer.menuItemCart.summary.color.secondary,
    fontSize: '0.85rem',
  },
  paymentContainer: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(5),
    },
    marginBottom: theme.spacing(10),
  },
  paymentMethodDisclaimer: {
    color: '#CC0000',
    fontSize: '0.8em',
    margin: theme.spacing(-1, 0, 2, 0),
  },
  pickupDisclaimer: {
    color: '#CC0000',
  },
  pickupOptionsBox: {
    margin: theme.spacing(2),
  },
  pickupVehicleColor: {
    marginTop: theme.spacing(2),
  },
  qualifyingItem: {
    '&:hover': {
      backgroundColor: `${theme.palette.common.white} !important`,
      opacity: 0.8,
    },
    paddingBottom: '0px',
    paddingTop: '0px',
  },
  qualifyingItemsDetail: {
    display: 'flex',
    flexDirection: 'column',
  },
  qualifyingItemsNotice: {
    '& svg': {
      color: '#FF0000',
      fontSize: 'x-large',
      marginRight: theme.spacing(0.5),
      verticalAlign: 'middle',
    },
    display: 'flex',
    margin: theme.spacing(1.25, 1.25, 0, 1.25),
    textAlign: 'unset',
  },
  qualifyingItemsText: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: theme.spacing(1.75),
    textAlign: 'left',
  },
  secondarySummaryText: {
    color: theme.consumer.menuItemCart.summary.color.secondary,
    fontSize: '0.85rem',
    marginLeft: theme.spacing(3),
  },
  sectionContainer: {
    marginBottom: theme.spacing(4),
  },
  sectionHeading: {
    display: 'flex',
    fontWeight: 'bold',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
  },
  sectionHeadingForm: {
    marginBottom: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
  sectionHeadingSecondary: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(5),
    },
    marginTop: theme.spacing(10),
  },
  sectionHeadingSpacing: {
    marginBottom: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
  },
  sectionHeadingUnderlined: {
    borderBottom: '1px solid #E7E7E7',
    display: 'flex',
    fontWeight: 'bold',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(0.5),
  },
  sectionHeadingWithEdit: {
    fontWeight: 'bold',
  },
  seeQualifyingItems: {
    '&:hover': {
      backgroundColor: `${theme.palette.common.white} !important`,
      opacity: 0.8,
    },
    padding: '0',
    textDecoration: 'underline',
    width: 'fit-content',
  },
  submitButtonContainerDesktop: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
      bottom: theme.spacing(-2),
      display: 'flex',
      flexDirection: 'column',
      margin: theme.spacing(0, -2),
      padding: theme.spacing(2),
      zIndex: 2,
    },
  },
  submitButtonContainerMobile: {
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      backgroundColor: 'white',
      bottom: 0,
      display: 'flex',
      flexDirection: 'column',
      left: 0,
      margin: 0,
      padding: theme.spacing(2),
      position: 'sticky',
      width: '100%',
      zIndex: 2,
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  summaryAndSubmitContainer: {
    top: '5em',
  },
  summaryContainer: {
    [theme.breakpoints.down('sm')]: {
      // Clear the fixed submit buttons container on mobile
      marginBottom: 0,
      paddingBottom: '112px',
      position: 'relative',
    },
  },
  summaryHeading: {
    [theme.breakpoints.down('sm')]: {
      borderBottom: '1px solid #E7E7E7',
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(-2),
      paddingBottom: theme.spacing(0.5),
      textTransform: 'none',
    },
    fontWeight: 'bold',
    marginBottom: theme.spacing(5),
    marginTop: '30px',
  },
  tableInput: {
    marginTop: theme.spacing(2),
  },
  tableNumberContainer: {
    '& svg': {
      marginRight: '10px',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(5),
    },
    marginBottom: theme.spacing(10),
  },
  tipBox: {
    '& .pm-form-group': {
      marginBottom: 0,
    },
    display: 'flex',
    gap: theme.spacing(1),
    justifyContent: 'flex-start',
    marginTop: theme.spacing(2),
    width: 'min-content',
  },
  tipField: {
    marginTop: theme.spacing(2),
    width: 345,
  },
  tipPercentButton: {
    '&:active, &:focus': {
      backgroundColor: theme.consumer.primaryColorOnWhite,
      color: theme.palette.getContrastText(theme.consumer.primaryColorOnWhite),
    },
    '@media(hover: none)': {
      '&:hover': {
        backgroundColor: `${theme.consumer.primaryColorOnWhite} !important`,
        color: theme.palette.getContrastText(theme.consumer.primaryColorOnWhite),
      },
    },
    backgroundColor: theme.palette.grey[300],
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '8px',
    minWidth: 80,
  },
  tipWarningText: {
    color: theme.palette.red.main,
    display: 'flex',
    fontSize: '0.75rem',
    justifyContent: 'flex-start',
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },
  totalContainer: {
    borderTop: '1px solid #CCC',
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    textAlign: 'right',
  },
  underline: {
    '&:hover': {
      textDecoration: 'none',
    },
    textDecoration: 'underline',
  },
  vehicleInputs: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  visibleSquareLine: {
    visibility: 'visible',
  },
});
