export default theme => ({
  cardContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'block',
      overflow: 'auto',
    },
    display: 'flex',
  },
  cardField: {
    backgroundColor: '#FFF',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
    height: '40px',
    marginRight: '1%',
    padding: theme.spacing(0, 1),
  },
  cvv: {
    [theme.breakpoints.down('md')]: {
      float: 'left',
      width: '32%',
    },
    width: '15%',
  },
  expiredDate: {
    [theme.breakpoints.down('md')]: {
      float: 'left',
      width: '32%',
    },
    width: '15%',
  },
  number: {
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(0.5),
      marginRight: 0,
      width: '100%',
    },
    width: '47%',
  },
  postalCode: {
    [theme.breakpoints.down('md')]: {
      float: 'left',
      width: '34%',
    },
    marginRight: 0,
    width: '20%',
  },
});
