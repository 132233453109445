export default theme => ({
  checkBoxGrid: {
    textAlign: 'left',
  },
  checkBoxGroup: {
    alignItems: 'start',
  },
  containerStyle: {
    marginTop: theme.spacing(2),
  },
  grayedText: {
    color: '#595959',
  },
  marketingOptInLanguage: {
    fontSize: '0.75em',
  },
  termsButton: {
    color: 'inherit',
    fontSize: 'inherit',
    lineHeight: 'inherit',
    minHeight: 0,
    padding: 0,
    textDecoration: 'underline',
    textTransform: 'none',
  },
});
