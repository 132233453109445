import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'react-form';

import CheckBox from './CheckBox';
import FormGroup from './FormGroup';
import { classNames, makeStyles } from '../../../utils/withStyles';
import { getReadability, readableFontColor } from '../../../utils/colors';

const styles = theme => ({
  textSecondary: {
    color: getReadability(theme.palette.background.default, theme.palette.primary.main) < 1.25 ? `${readableFontColor(theme.palette.primary.main)} !important` : null,
  },
});

const useStyles = makeStyles(styles);

const CheckBoxGroup = (props) => {
  const { disabled, field, inline, offset, onChange, radioCheck, style, validate, rootClassName, formGroupProps, setFieldError, ...inputProps } = props;
  const { value, setValue, meta: { error } } = useField(field, { validate });
  const classes = useStyles();

  useEffect(() => {
    if (typeof setFieldError === 'function') {
      setFieldError(error);
    }
  }, [error, setFieldError]);

  return (
    <FormGroup {...formGroupProps} style={{ ...(style), display: inline ? 'inline' : 'block' }}>
      <CheckBox
        checked={!!value}
        data-tour-id={props['data-tour-id']}
        disabled={disabled}
        field={field}
        rootClassName={classNames(classes.textSecondary, rootClassName)}
        onChange={() => {
          const uncheckedValue = props.value === true ? false : null;
          const nextValue = String(value || null) === String(props.value || null) ? uncheckedValue : props.value;
          if (typeof onChange === 'function') {
            onChange(nextValue);
          }
          setValue(nextValue);
        }}
        radioCheck={radioCheck}
        {...inputProps}
      />
    </FormGroup>
  );
};

CheckBoxGroup.defaultProps = {
  'data-tour-id': null,
  disabled: false,
  formGroupProps: null,
  inline: false,
  offset: undefined,
  onChange: null,
  radioCheck: false,
  rootClassName: null,
  style: null,
  title: null,
  titleTooltip: null,
  validate: null,
  value: true,
};

CheckBoxGroup.propTypes = {
  'data-tour-id': PropTypes.string,
  disabled: PropTypes.bool,
  field: PropTypes.string.isRequired,
  formGroupProps: PropTypes.object,
  inline: PropTypes.bool,
  offset: PropTypes.number,
  onChange: PropTypes.func,
  radioCheck: PropTypes.bool,
  rootClassName: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  titleTooltip: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  validate: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
};

export default CheckBoxGroup;
