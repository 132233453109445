/* eslint-disable no-useless-escape */

// TODO: sc-64537, add more countries and update current ones
/**
 * @see https://github.com/unicode-org/cldr/blob/release-26-0-1/common/supplemental/postalCodeData.xml
 */
const POSTAL_CODE_REGEX: Record<string, RegExp | null> = {
  AE: null, // United Arab Emirates
  AR: /([A-HJ-NP-Z])?\d{4}([A-Z]{3})?/, // Argentina
  AU: /\d{4}/, // Australia
  BS: null, // The Bahamas
  CA: /[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ ]?\d[ABCEGHJ-NPRSTV-Z]\d/, // Canada
  DO: /\d{5}/, // Dominican Republic
  FR: /\d{2}[ ]?\d{3}/, // France
  GB: /GIR[ ]?0AA|((AB|AL|B|BA|BB|BD|BH|BL|BN|BR|BS|BT|CA|CB|CF|CH|CM|CO|CR|CT|CV|CW|DA|DD|DE|DG|DH|DL|DN|DT|DY|E|EC|EH|EN|EX|FK|FY|G|GL|GY|GU|HA|HD|HG|HP|HR|HS|HU|HX|IG|IM|IP|IV|JE|KA|KT|KW|KY|L|LA|LD|LE|LL|LN|LS|LU|M|ME|MK|ML|N|NE|NG|NN|NP|NR|NW|OL|OX|PA|PE|PH|PL|PO|PR|RG|RH|RM|S|SA|SE|SG|SK|SL|SM|SN|SO|SP|SR|SS|ST|SW|SY|TA|TD|TF|TN|TQ|TR|TS|TW|UB|W|WA|WC|WD|WF|WN|WR|WS|WV|YO|ZE)(\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}))|BFPO[ ]?\d{1,4}/, // United Kingdom
  GR: /\d{3}[ ]?\d{2}/, // Greece
  GU: /969[123]\d([ \-]\d{4})?/, // Guam
  IN: /\d{6}/, // India
  JE: /JE\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}/, // Jersey
  JP: /\d{3}-\d{4}/, // Japan
  KR: /\d{3}[\-]\d{3}/, // South Korea
  MX: /\d{5}/, // Mexico
  PR: /00[679]\d{2}([ \-]\d{4})?/, // Puerto Rico
  QA: null, // Qatar
  US: /\d{5}([ \-]\d{4})?/, // United States
};

export const isValidPostalCodeFormat = (postalCode: string, countryIsoCode: string) => {
  const countryPostalCodeRegex = POSTAL_CODE_REGEX[countryIsoCode];

  if (countryPostalCodeRegex) {
    return countryPostalCodeRegex.test(postalCode);
  }

  return !!postalCode;
};
