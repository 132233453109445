import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { compose, mapProps } from '@shakacode/recompose';
import { withRouter } from 'react-router-dom';
import { Button, Icon } from '@popmenu/common-ui';
import { ShoppingBag } from '@popmenu/web-icons';

import { getOrderingPageUrl, useMenuItemCartVariables } from '../MenuItemCartHelpers';
import { withStyles } from '../../../../utils/withStyles';
import { withWindowContext } from '../../../../shared/WindowProvider';
import styles from '../CartButton/styles';
import sessionWithMenuItemCartQuery from '../../../../libs/gql/queries/menu_item_carts/sessionWithMenuItemCartQuery.gql';
import { useRestaurantContext } from '../../../../utils/useRestaurantContext';

import BasicModal from '../../../../admin/shared/BasicModal';
import DineInCartButtonSummary from '../DineInCartButtonSummary';
import Query from '../../../../shared/Query';

const DineInCartButton = ({ classes, disabled, isMenuSelected, viewMyTabButton, viewMyTabButtonText }) => {
  const [showCartModal, setShowCartModal] = useState(false);
  const restaurant = useRestaurantContext({ keys: ['id', 'onlineOrderDefaultUrl', 'onlineOrderCateringUrl'] });

  const menuItemCartVariables = useMenuItemCartVariables({ restaurantId: restaurant.id });
  const { cartType } = menuItemCartVariables;

  if (!cartType) {
    return null;
  }

  const orderingPageUrl = getOrderingPageUrl({ cartType, restaurant });
  if (!orderingPageUrl) {
    return null;
  }

  return (
    <Query
      query={sessionWithMenuItemCartQuery}
      variables={menuItemCartVariables}
    >
      {({ data, loading }) => {
        if (loading || !data || !data.currentSession || !data.currentSession.pendingMenuItemCart) {
          return null;
        }
        const menuItemCart = data.currentSession.pendingMenuItemCart;
        const showBottomCartButton = menuItemCart.lastSubcartSubmittedAt || isMenuSelected;
        let quantity = 0;
        menuItemCart.selectedMenuItems.forEach((selectedMenuItem) => {
          quantity += (selectedMenuItem.quantity || 0);
        });

        return (
          <React.Fragment>
            {viewMyTabButton ? (
              showBottomCartButton && (
              <Button
                fullWidth
                color="primary"
                disabled={disabled}
                textTransform="none"
                onClick={() => setShowCartModal(true)}
                size="large"
                variant="contained"
                data-cy="bottom_cart_button"
              >
                {
                  viewMyTabButtonText || (
                    <FormattedMessage
                      id="ordering.dine_in.view_my_order"
                      defaultMessage="View My Order"
                    />
                  )
                }
              </Button>
              )
            ) : (
              <div className={classes.buttonContainer}>
                <Button
                  aria-label="View My Order"
                  className={classes.dineInButton}
                  startIcon={<Icon icon={ShoppingBag} />}
                  onClick={() => setShowCartModal(true)}
                  size="large"
                  variant="text"
                  data-cy="cart_button"
                />
                {quantity !== 0 && (
                  <div aria-hidden className={classes.buttonQuantity}>
                    {quantity}
                  </div>
                )}
              </div>
            )}
            <BasicModal
              className={classes.dineInSummaryModal}
              closeModal={() => setShowCartModal(false)}
              show={showCartModal}
              scroll="paper"
              size="md"
              fullScreenMobile
            >
              <DineInCartButtonSummary
                menuItemCart={menuItemCart}
                classes={classes}
                customPageUrl={orderingPageUrl}
                closeShowCartModal={() => setShowCartModal(false)}
              />
            </BasicModal>
          </React.Fragment>
        );
      }}
    </Query>
  );
};
DineInCartButton.defaultProps = {
  disabled: false,
  isMenuSelected: false,
};

DineInCartButton.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  isMenuSelected: PropTypes.bool,
  scrolledHeader: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(
  withWindowContext,
  mapProps(({ window, ...props }) => ({
    ...props,
    scrolledHeader: window.scrolledHeader,
  })),
  withRouter,
  withStyles(styles),
)(DineInCartButton);
