import { Icon, Typography } from '@popmenu/common-ui';
import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { ChevronDown } from '@popmenu/web-icons';
import { FormattedMessage } from 'react-intl';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { formatCurrency } from '~/utils/currency';
import { AH } from '../../shared/AccessibleHeading';

// ----------------- Toast Menu Item Cart Helpers --------------------

export const loadTokenIframeScript = (setTokenizationScriptStatus) => {
  console.log('[POPMENU] [Token iframe] Loading scripts...');
  const src = 'https://core.spreedly.com/iframe/iframe-v1.min.js';
  new Promise(((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;

    script.addEventListener('load', () => {
      resolve();
    });
    script.addEventListener('error', (e) => {
      reject(e);
    });

    document.body.appendChild(script);
  })).then(() => {
    console.log('[POPMENU] [Token iframe] Loaded script!');

    setTokenizationScriptStatus(true);
  }).catch((err) => {
    console.error(`[POPMENU] [Token iframe] Error loading script: ${err.toString()}`);
  });
};

// ----------------- Common Menu Item Cart Helpers --------------------

export const AccordionDiv = ({ menuItemCart, classes, defaultExpanded }) => (
  <div>
    <Accordion defaultExpanded={!!defaultExpanded}>
      <AccordionSummary
        expandIcon={<Icon icon={ChevronDown} className={classes.expandIcon} />}
      >
        <AH variant="h6" className={classes.accordionSummaryText}>
          <FormattedMessage id="consumer.ordering.order_history" defaultMessage="All My Orders" />
        </AH>
      </AccordionSummary>
      <AccordionDetails className={classes.allItemsContainer}>
        {menuItemCart.selectedMenuItems.map(smi => (
          <div key={smi.id} className={classes.itemDetails}>
            <Typography variant="body2">
              {smi.menuItemName}
            </Typography>
            <Typography variant="body2" style={{ textDecoration: smi.isVoid ? 'line-through' : 'none' }}>
              <FormattedMessage
                id="consumer.ordering.items"
                defaultMessage="{currency} x{quantity}"
                values={{
                  currency: formatCurrency(smi.price, 'usd', { showDecimals: true, showSymbol: true }),
                  quantity: smi.quantity,
                }}
              />
            </Typography>
          </div>
        ))}
      </AccordionDetails>
    </Accordion>
  </div>
);
