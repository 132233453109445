import React, { useEffect } from 'react';
import { CardElement, useElements } from '@stripe/react-stripe-js';

const CARD_OPTIONS = {
  hidePostalCode: false,
  iconStyle: 'solid',
  style: {
    base: {
      '::placeholder': { color: 'rgba(0, 0, 0, 0.54)' },
      ':-webkit-autofill': { color: '#000' },
      color: '#000',
      fontSize: '17px',
      fontSmoothing: 'antialiased',
      fontWeight: 500,
    },
  },
};

const MenuItemCartStripePaymentFields = ({ onChange }) => {
  const stripeElements = useElements();

  useEffect(() => {
    let cardElement = null;

    if (onChange && stripeElements) {
      cardElement = stripeElements.getElement(CardElement);

      if (cardElement) {
        cardElement.on('change', onChange);
      }
    }

    return () => {
      if (cardElement && onChange) {
        cardElement.removeEventListener('change', onChange);
      }
    };
  }, [onChange, stripeElements]);

  return (
    <div style={{ border: '1px solid rgba(0, 0, 0, 0.23)', borderRadius: '4px', padding: '18.5px 14px' }}>
      <CardElement options={CARD_OPTIONS} />
    </div>
  );
};

export default MenuItemCartStripePaymentFields;
