const fbqPromise = typeof window === 'undefined' ? null : new Promise((resolve) => { window.fbqResolvePromise = resolve; });

export const trackFacebookEvent = async (eventName, options = null) => {
  if (typeof window === 'undefined') {
    console.error('the function trackFacebookEvent should never be called during SSR.');
    return false;
  }
  try {
    if (!window.fbq) {
      await fbqPromise;
    }

    if (typeof window.fbq === 'function') {
      if (options) {
        window.fbq('track', eventName, options);
      } else {
        window.fbq('track', eventName);
      }
    }
    return true;
  } catch (err) {
    // Prevent FB pixel errors from ever crashing the page
    console.warn(`[POPMENU] FB Pixel Error: ${err.toString()}`);
    return false;
  }
};
