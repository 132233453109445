import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { formatPhone } from '../../utils/utils';
import { useIntl } from '../../utils/withIntl';
import { useRestaurantContext } from '../../utils/useRestaurantContext';

const PhoneHelpText = ({ className }) => {
  const t = useIntl();
  const {
    customSmsContactDisclaimer,
    firstAvailablePhoneNumber,
  } = useRestaurantContext({
    keys: [
      'customSmsContactDisclaimer',
      'firstAvailablePhoneNumber',
    ],
  });

  if (customSmsContactDisclaimer) {
    return ` ${customSmsContactDisclaimer}`;
  }

  if (!firstAvailablePhoneNumber) {
    return null;
  }
  const phoneNumberButton = (
    <a
      aria-label={`${firstAvailablePhoneNumber} ${t('generic.telephone')}`}
      className={className}
      href={`tel:${firstAvailablePhoneNumber}`}
    >
      {formatPhone(firstAvailablePhoneNumber)}
    </a>
  );
  return (
    <FormattedMessage
      id="phone_help_text.subscribe_sms_help"
      defaultMessage=" Call {phoneNumberButton} for help."
      values={{ phoneNumberButton }}
    />
  );
};

PhoneHelpText.defaultProps = {
  className: null,
};

PhoneHelpText.propTypes = {
  className: PropTypes.string,
};

export default PhoneHelpText;
