import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { loadTokenIframeScript } from '../../SubmitMenuItemCartHelpers';
import { classNames, makeStyles } from '../../../../../utils/withStyles';
import { formatCardExpiryDate } from '../../../../../utils/forms';
import styles from './styles';
import Loading from '../../../../../shared/Loading';
import { useSnackbar } from '../../../../../utils/withSnackbar';
import { isValidPostalCodeFormat } from '../../../../../utils/postalCodeRegex';
import { usePopmenuConfig } from '../../../../../utils/withPopmenuConfig';

const useStyles = makeStyles(styles);

const SpreedlyPaymentFields = ({ country, canBalanceBeFullyPaidByThirdPartyGiftCard, isEcardGiftCardsActive, onPaymentReady, onTokenized, onValidityChanged }) => {
  const [tokenizationScriptLoaded, setTokenizationScriptLoaded] = useState(false);
  const validCardData = useRef(false);
  const { showSnackbarError } = useSnackbar();
  const classes = useStyles();

  const { spreedlyKey } = usePopmenuConfig();

  const expirationDateField = useRef();
  const postalCodeField = useRef();

  const checkValidity = useCallback(() => {
    const expirationDate = expirationDateField.current.value.toString().split(' / ').join('/');
    const postalCode = postalCodeField.current.value;
    const isValidPostalCode = postalCode && country ?
      isValidPostalCodeFormat(postalCode, country) :
      postalCode;
    const isValidExpirationDateFormat = /^(0[1-9]|1[0-2])\/?([0-9]{2})$/.test(expirationDate);

    onValidityChanged(isValidPostalCode && isValidExpirationDateFormat && validCardData.current);
  }, [onValidityChanged, country]);

  useEffect(() => {
    console.log('Spreedly', 'Loading Script');
    loadTokenIframeScript(setTokenizationScriptLoaded);
  }, []);

  useEffect(() => {
    if (!tokenizationScriptLoaded) {
      return undefined;
    }

    window.Spreedly.init(spreedlyKey, {
      cvvEl: 'spreedly-cvv',
      numberEl: 'spreedly-number',
    });

    window.Spreedly.on('ready', () => {
      console.log('Spreedly ready....');

      window.Spreedly.setParam('allow_blank_name', true);
      window.Spreedly.setParam('allow_expired_date', true);

      window.Spreedly.setPlaceholder('number', 'Card Number');
      window.Spreedly.setFieldType('number', 'text');
      window.Spreedly.setStyle('number', 'font-size: 16px; width: 100%; height: 40px;');
      window.Spreedly.setNumberFormat('prettyFormat');

      window.Spreedly.setPlaceholder('cvv', 'CVV');
      window.Spreedly.setStyle('cvv', 'font-size: 16px; width: 100%; height: 40px;');
      window.Spreedly.setFieldType('cvv', 'number');
    });

    window.Spreedly.on('errors', errors => errors.forEach(error => showSnackbarError(`Payment error. ${error.message}`)));

    window.Spreedly.on('paymentMethod', async (token, pmData) => {
      console.log('Tokenization success...');
      onTokenized({
        cardLast4: pmData.last_four_digits,
        expirationDate: expirationDateField.current.value.toString().split(' / ').join('/'),
        fingerprint: pmData.fingerprint,
        firstSixDigits: pmData.first_six_digits,
        postalCode: postalCodeField.current.value,
        token,
      });
    });

    window.Spreedly.on('fieldEvent', (name, type, _activeEl, inputProperties) => {
      if ((name === 'number' || name === 'cvv') && type === 'input') {
        validCardData.current = inputProperties.validCvv && inputProperties.validNumber;
        checkValidity();
      }
    });

    onPaymentReady(
      () => {
        if (isEcardGiftCardsActive && canBalanceBeFullyPaidByThirdPartyGiftCard) return;

        const expirationDate = expirationDateField.current.value;
        const postalCode = postalCodeField.current.value;
        const monthAndYear = expirationDate.split(' / ');
        window.Spreedly.tokenizeCreditCard({
          month: monthAndYear[0],
          year: `20${monthAndYear[1]}`,
          zip: postalCode,
        });
      },
      {
        validatesForm: true,
      },
    );

    return () => window.Spreedly.removeHandlers();
  }, [canBalanceBeFullyPaidByThirdPartyGiftCard, isEcardGiftCardsActive, spreedlyKey, tokenizationScriptLoaded, showSnackbarError, onTokenized, country, onPaymentReady, checkValidity]);

  if (!tokenizationScriptLoaded) {
    return <Loading />;
  }

  return (
    <div className={classes.cardContainer}>
      <div id="spreedly-number" className={classNames(classes.cardField, classes.number)} />
      <div id="spreedly-cvv" className={classNames(classes.cardField, classes.cvv)} />
      <input
        type="tel"
        ref={expirationDateField}
        placeholder="MM/YY"
        className={classNames(classes.cardField, classes.expiredDate)}
        onChange={(e) => {
          expirationDateField.current.value = formatCardExpiryDate(e);
          checkValidity();
        }}
      />
      <input
        type="tel"
        ref={postalCodeField}
        placeholder="ZIP"
        maxLength="7"
        className={classNames(classes.cardField, classes.postalCode)}
        onChange={() => {
          checkValidity();
        }}
        autoComplete="postal-code"
      />
    </div>
  );
};

SpreedlyPaymentFields.propTypes = {
  country: PropTypes.string.isRequired,
  onPaymentReady: PropTypes.func.isRequired,
  onTokenized: PropTypes.func.isRequired,
  onValidityChanged: PropTypes.func.isRequired,
};

export default SpreedlyPaymentFields;
