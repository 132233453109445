import React from 'react';

import PropTypes from 'prop-types';
import { compose } from '@shakacode/recompose';
import { withSnackbar } from '../../../../utils/withSnackbar';
import { withRestaurant } from '../../../../utils/withRestaurant';
import { currentUserShape, withCurrentUser } from '../../../../shared/CurrentSessionProvider';

import StripeProvider from '../../../../shared/StripeProvider/StripeProvider';
import MenuItemCartCloseTabForm from './MenuItemCartCloseTabForm';
import MenuItemCartSquareCloseTab from './MenuItemCartSquareCloseTab';
import MenuItemCartStripeCloseTab from './MenuItemCartStripeCloseTab';
import MenuItemCartToastCloseTab from './MenuItemCartToastCloseTab';

const MenuItemCartCloseTab = ({ closeTab, currentUser, menuItemCart, restaurant, showSnackbarError, customPageUrl }) => {
  // When guest has not skipped payment (payment is already saved to menu_item_cart) or payment step hidden. Otherwise show summary with payment
  if (menuItemCart.hasPayment || menuItemCart.location.isOrderingDineInHidePaymentStepEnabled) {
    return (
      <MenuItemCartCloseTabForm
        closeTab={closeTab}
        currentUser={currentUser}
        customPageUrl={customPageUrl}
        menuItemCart={menuItemCart}
        restaurant={restaurant}
        showSnackbarError={showSnackbarError}
      />
    );
  } else if (menuItemCart.location.isSquarePayment) {
    return (
      <MenuItemCartSquareCloseTab
        closeTab={closeTab}
        currentUser={currentUser}
        customPageUrl={customPageUrl}
        menuItemCart={menuItemCart}
        restaurant={restaurant}
        showSnackbarError={showSnackbarError}
      />
    );
  } else if (menuItemCart.location.isToastPayment) {
    return (
      <MenuItemCartToastCloseTab
        closeTab={closeTab}
        currentUser={currentUser}
        customPageUrl={customPageUrl}
        menuItemCart={menuItemCart}
        restaurant={restaurant}
        showSnackbarError={showSnackbarError}
      />
    );
  } else if (menuItemCart.location.isStripePayment) {
    return (
      <StripeProvider stripePublishableKey={menuItemCart.location.stripePublishableKey}>
        <MenuItemCartStripeCloseTab
          closeTab={closeTab}
          currentUser={currentUser}
          customPageUrl={customPageUrl}
          menuItemCart={menuItemCart}
          restaurant={restaurant}
          showSnackbarError={showSnackbarError}
        />
      </StripeProvider>
    );
  } else {
    return null;
  }
};

MenuItemCartCloseTab.defaultProps = {
  currentUser: null,
};

MenuItemCartCloseTab.propTypes = {
  closeTab: PropTypes.func.isRequired,
  currentUser: currentUserShape,
  menuItemCart: PropTypes.shape({
    id: PropTypes.number,
    location: PropTypes.shape({
      isSquarePaymentAvailable: PropTypes.bool,
      isStripePaymentAvailable: PropTypes.bool,
      isToastPaymentAvailable: PropTypes.bool,
      squareLocationId: PropTypes.string,
      stripePublishableKey: PropTypes.string,
    }),
  }).isRequired,
  restaurant: PropTypes.shape({
    customConversionScripts: PropTypes.array,
    googleAdsConversionActionScripts: PropTypes.string,
  }).isRequired,
  showSnackbarError: PropTypes.func.isRequired,
};

export default compose(
  withCurrentUser,
  withRestaurant,
  withSnackbar,
)(MenuItemCartCloseTab);
