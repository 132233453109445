export default theme => ({
  disclaimer: {
    color: 'inherit',
    fontSize: '0.75em',
    marginTop: theme.spacing(2),
  },
  grayedText: {
    color: '#595959 !important',
  },
  termsButton: {
    color: 'inherit',
    fontSize: 'inherit',
    lineHeight: 'inherit',
    minHeight: 0,
    padding: 0,
    textDecoration: 'underline',
    textTransform: 'none',
  },
});
