import React from 'react';
import { compose, mapProps } from '@shakacode/recompose';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Elements } from '@stripe/react-stripe-js';

import { connect } from '~/utils/redux';
import { setStripeInstance, type StripeInstance } from '../StripeActions';
import { withPopmenuConfig, type WithPopmenuConfigProps } from '../../utils/withPopmenuConfig';

interface StripeProviderProps {
  children: React.ReactNode;
  stripePublishableKey?: string | null;
}

interface InnerStripeProviderProps extends StripeProviderProps {
  setStripeInstance: (stripeInstance: StripeInstance) => void;
  stripeInstance: StripeInstance;
}

class StripeProvider extends React.PureComponent<InnerStripeProviderProps> {
  static readonly defaultProps = {
    stripeInstance: null,
    stripePublishableKey: null,
  };

  componentDidMount() {
    if (this.props.stripeInstance || !this.props.stripePublishableKey) {
      return;
    }
    this.props.setStripeInstance(loadStripe(this.props.stripePublishableKey));
  }

  render() {
    return (
      <Elements stripe={this.props.stripeInstance}>
        {this.props.children}
      </Elements>
    );
  }
}

const connector = connect(state => ({
  stripeInstance: state.stripe.stripeInstance,
}), {
  setStripeInstance,
});

export default compose<InnerStripeProviderProps, StripeProviderProps>(
  withPopmenuConfig,
  mapProps<
    StripeProviderProps,
    StripeProviderProps & WithPopmenuConfigProps
  >(({ popmenuConfig, ...props }) => ({
    stripePublishableKey: popmenuConfig.stripePublishableKey,
    ...props,
  })),
  connector,
)(StripeProvider);
