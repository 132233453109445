import React from 'react';
import PropTypes from 'prop-types';
import { compose, mapProps } from '@shakacode/recompose';
import { FormattedMessage } from 'react-intl';
import { Grid, Typography } from '@popmenu/common-ui';

import { useFeatureFlags } from '../../../utils/featureFlagsContext';
import { withRestaurant } from '../../../utils/withRestaurant';
import { withStyles, classNames } from '../../../utils/withStyles';
import marketingOptInRequestStyles from './styles';

import { CheckBoxGroup } from '../../../admin/shared/forms/BasicForm';
import PhoneHelpText from '../PhoneHelpText';

const MarketingOptInRequest = (props) => {
  const textClasses = (
    props.textClassName ||
    (
      props.isCheckout ?
        classNames(props.grayedText ? props.classes.grayedText : null) :
        classNames(props.classes.marketingOptInLanguage, props.grayedText ? props.classes.grayedText : null)
    )
  );
  const { isFeatureActive } = useFeatureFlags();
  const isEmailOptInEnabled = props.showBothOptIns || isFeatureActive('isEmailOptInEnabled');
  const isSmsOptInEnabled = props.showBothOptIns || isFeatureActive('isSmsOptInEnabled');
  const { isLoyaltyEnabled } = props;

  return (
    <Grid
      container
      className={!props.isDineIn ? props.classes.containerStyle : null}
      data-testid="opt-in-checkboxes"
    >
      {isEmailOptInEnabled && (
        <Grid item xs={12} className={props.classes.checkBoxGrid}>
          <CheckBoxGroup
            className={props.classes.checkBoxGroup}
            field="subscribeEmail"
            title={(
              <Typography align="left" className={textClasses}>
                <FormattedMessage
                  id="sessions.marketing_optin.email_checkbox"
                  defaultMessage="By checking this box, you are requesting to receive marketing emails from {restaurantName}."
                  values={{
                    restaurantName: props.restaurantName,
                  }}
                />
              </Typography>
            )}
            rootClassName={textClasses}
            inline
          />
        </Grid>
      )}
      {/* NCR loyalty require phone number, so we must ask for marketing text opt-ins */}
      {(isSmsOptInEnabled || isLoyaltyEnabled) && (
        <Grid item xs={12} className={props.classes.checkBoxGrid}>
          <CheckBoxGroup
            className={props.classes.checkBoxGroup}
            disabled={props.smsOptInDisabled}
            field="subscribeSms"
            title={(
              <Typography align="left" className={textClasses}>
                <FormattedMessage
                  id="marketing_opt_in_request.sms_checkbox"
                  defaultMessage="By checking this box, you are requesting to receive marketing text messages (maximum one per week) from {restaurantName} and authorizing {restaurantName} to send a text message to the telephone number provided by you using an automated system for the selection of telephone numbers. You can unsubscribe at any time by replying with STOP. You are not required to agree to enter into this agreement as a condition of purchasing any property, goods, or services. Message and data rates may apply.{helpNumberText}"
                  values={{
                    helpNumberText: (
                      <PhoneHelpText
                        className={props.classes.termsButton}
                      />
                    ),
                    restaurantName: props.restaurantName,
                  }}
                />
              </Typography>
            )}
            rootClassName={textClasses}
            inline
          />
        </Grid>
      )}
    </Grid>
  );
};

MarketingOptInRequest.defaultProps = {
  grayedText: false,
  isCheckout: false,
  isDineIn: false,
  restaurantName: null,
  showBothOptIns: false,
  smsOptInDisabled: false,
  textClassName: null,
};

MarketingOptInRequest.propTypes = {
  classes: PropTypes.object.isRequired,
  grayedText: PropTypes.bool,
  isCheckout: PropTypes.bool,
  isDineIn: PropTypes.bool,
  restaurantName: PropTypes.string,
  showBothOptIns: PropTypes.bool,
  smsOptInDisabled: PropTypes.bool,
  textClassName: PropTypes.string,
};

export default compose(
  withStyles(marketingOptInRequestStyles),
  withRestaurant,
  mapProps(({ restaurant, ...props }) => ({
    ...props,
    restaurantName: restaurant && restaurant.name,
    signUpSetting: restaurant && restaurant.signUpSetting,
  })),
)(MarketingOptInRequest);
