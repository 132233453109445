export default theme => ({
  fixedMobileTabsContainer: {
    backgroundColor: 'white !important',
    boxShadow: '0 0 3px rgba(0, 0, 0, 0.25)',
    display: 'none',
    padding: theme.spacing(2, 4),
  },
  fixedMobileTabsContainerStuck: {
    display: 'block',
    maxWidth: '100%',
  },
  fixedMobileTabsMainContainer: {
    [theme.mixins.highZoomMediaQuery]: {
      position: 'sticky',
    },
    backgroundColor: 'white !important',
    marginLeft: theme.spacing(-2),
    top: 0,
    width: '100vw',
    zIndex: 6,
  },
  legacyTabButton: {
    '&.pm-menu-toggle': {
      background: 'none',
      borderBottom: '1px solid transparent',
      borderLeft: 'none',
      borderRadius: 0,
      borderRight: 'none',
      borderTop: '1px solid transparent',
      boxShadow: 'none !important',
      color: theme.consumer.menus.tabs.color,
      cursor: 'pointer',
      display: 'inline-block',
      fontSize: '1.2em',
      fontWeight: 'bold',
      height: 'auto',
      letterSpacing: '2px',
      margin: '5px 15px',
      padding: '5px 0',
      textTransform: 'uppercase',
    },
    '&.pm-menu-toggle:focus, &.pm-menu-toggle:hover': {
      background: 'none',
      borderColor: theme.consumer.menus.tabs.borderColor,
      color: theme.consumer.menus.tabs.color,
      opacity: 0.8,
    },
  },
  legacyTabButtonSelected: {
    '&.pm-menu-toggle': {
      borderColor: theme.consumer.menus.tabs.borderColor,
    },
  },
  legacyTabList: {
    marginBottom: '30px',
    textAlign: 'center',
  },
  materialTabsContainer: {
    marginBottom: theme.spacing(2),
    position: 'sticky',
    top: 0,
  },
  materialTabsContainerStuck: {
    boxShadow: '0 0 3px rgba(0, 0, 0, 0.25)',
    zIndex: 6,
  },
  menuCardAppBar: {
    backgroundColor: 'white',
    boxShadow: '0 8px 6px -8px gray',
    display: 'revert',
    margin: 'auto',
    marginBottom: theme.spacing(0.5),
    padding: theme.spacing(0.5),
    position: 'sticky',
    verticalAlign: 'top',
    zIndex: 5,
  },
  menuCardAppBarBtn: {
    alignSelf: 'flex-start',
    paddingLeft: '0px !important',
  },
  menuCardAppBarBtnText: {
    fontWeight: 'bold',
  },
  menuCardAppBarChips: {
    margin: theme.spacing(1, 1, 1, 0),
    width: 'fit-content',
  },
  menuCardAppBarChipsMain: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'start',
    },
    display: 'flex',
    justifyContent: 'center',
    margin: 'auto',
    overflowX: 'auto',
  },
  menuCardAppBarV2: {
    backgroundColor: theme.palette.surface.main,
    display: 'revert',
    margin: 'auto',
    position: 'sticky',
    verticalAlign: 'top',
    zIndex: 5,
  },
  menuCardWrap: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    margin: 'auto',
    marginTop: theme.spacing(-2),
    maxWidth: '1200px',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  menuCardWrapV2: {
    margin: theme.spacing(0, -2),
    width: `calc(100% + ${theme.spacing(4)}px)`,
  },
  menuTab: {
    opacity: 1,
  },
  mobileTabsDropdown: {
    color: 'black',
    display: 'block',
    width: '100%',
  },
  selectText: {
    margin: theme.spacing(4),
  },
  sidebarGroupContainer: {
    position: 'relative',
  },
  sidebarMenusContainer: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
    border: `6px double ${theme.palette.primary.main}`,
    marginLeft: theme.spacing(42),
  },
  sidebarTabButton: {
    '&:last-child': {
      marginBottom: 0,
    },
    color: theme.consumer.menus.tabs.color,
    display: 'block',
    fontSize: '1.2em',
    fontWeight: '900',
    marginBottom: theme.spacing(0.5),
    textAlign: 'left',
    textTransform: 'uppercase',
    width: '100%',
  },
  sidebarTabButtonSection: {
    '&:last-child': {
      marginBottom: 0,
    },
    color: theme.consumer.menus.tabs.color,
    display: 'block',
    fontSize: '1em',
    fontWeight: 'normal',
    marginBottom: theme.spacing(0.5),
    padding: theme.spacing(0.25, 4),
    textAlign: 'left',
    textTransform: 'none',
    width: '100%',
  },
  sidebarTabButtonSectionSelected: {
    fontWeight: 'bold',
  },
  sidebarTabButtonSelected: {
    fontWeight: 'bold',
  },
  sidebarTabsContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    float: 'left',
    marginTop: theme.spacing(2),
    maxHeight: '100vh',
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: theme.spacing(2),
    position: 'sticky',
    top: 0,
    width: theme.spacing(40),
  },
  staticMobileTabsContainer: {
    display: 'block',
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    padding: theme.spacing(2, 4),
    position: 'sticky',
    top: 0,
    zIndex: 6,
  },
  staticMobileTabsContainerStuck: {
    display: 'none',
  },
  viewMyTabButtonContainer: {
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      backgroundColor: 'white',
      bottom: 0,
      boxShadow: '0px -10px 15px rgb(242, 242, 242)',
      display: 'flex',
      flexDirection: 'column',
      left: 0,
      margin: theme.spacing(0, -2),
      padding: theme.spacing(2),
      position: 'sticky',
      zIndex: 5,
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
});
