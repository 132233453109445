import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, mapProps } from '@shakacode/recompose';
import { FormattedMessage } from 'react-intl';
import { Button, Typography } from '@popmenu/common-ui';

import { openTermsModal } from '../../ModalActions';
import { useFeatureFlags } from '../../../utils/featureFlagsContext';
import { withRestaurant } from '../../../utils/withRestaurant';
import { withStyles, classNames } from '../../../utils/withStyles';
import { TermsModalType } from '../../../utils/types';
import followDisclaimerStyles from './styles';

import PhoneHelpText from '../PhoneHelpText';

const FollowDisclaimer = (props) => {
  const { isFeatureActive } = useFeatureFlags();
  const isSmsOptInEnabled = isFeatureActive('isSmsOptInEnabled');

  let giveawayDisclaimer = null;
  let giveawayTermsButton = null;

  if (props.showGiveawayTerms) {
    giveawayDisclaimer = ' and is sponsoring this giveaway';
    giveawayTermsButton = (
      <React.Fragment>
        <Button
          className={props.classes.termsButton}
          onClick={() => props.openTermsModal(TermsModalType.Giveaway)}
          size="small"
          variant="text"
        >
          <FormattedMessage
            id="follower.giveaway_terms"
            defaultMessage="Giveaway Terms"
          />
        </Button>
        <FormattedMessage
          id="generic.and"
          defaultMessage=" and "
        />
      </React.Fragment>
    );
  }

  const privacyButton = (
    <Button
      className={props.classes.termsButton}
      onClick={() => props.openTermsModal(TermsModalType.Privacy)}
      variant="text"
    >
      <FormattedMessage id="follower.privacy_policy" defaultMessage="Privacy Policy" />
    </Button>
  );

  const disclaimer = (
    props.customDisclaimer ||
    (!isSmsOptInEnabled ? (
      <FormattedMessage
        id="follower.social_disclaimer"
        defaultMessage="By providing your phone number, you are requesting to receive marketing text messages (maximum one per week) from {restaurantName} and authorizing {restaurantName} to send a text message to the telephone number provided by you using an automated system for the selection of telephone numbers. You can unsubscribe at any time by replying with STOP. You are not required to agree to enter into this agreement as a condition of purchasing any property, goods, or services. Message and data rates may apply.{helpNumberText}"
        values={{
          helpNumberText: (
            <PhoneHelpText
              className={props.classes.termsButton}
            />
          ),
          restaurantName: props.restaurantName,
        }}
      />
    ) : null)
  );
  return (
    <Typography
      align={props.align}
      className={classNames(props.classes.disclaimer, props.grayedText ? props.classes.grayedText : null)}
      data-testid="follow-disclaimer"
      variant="body2"
    >
      <FormattedMessage
        id="follower.disclaimer"
        defaultMessage="You are submitting your information to {restaurantName}Popmenu, which powers this site{giveawayDisclaimer}. Popmenu&apos;s {giveawayTermsButton}{privacyButton} {apply}. "
        values={{
          apply: giveawayTermsButton && privacyButton ? 'apply' : 'applies',
          giveawayDisclaimer,
          giveawayTermsButton,
          privacyButton,
          restaurantName: props.restaurantName ? `${props.restaurantName} and ` : null,
        }}
      />
      {disclaimer}
    </Typography>
  );
};

FollowDisclaimer.defaultProps = {
  align: 'center',
  className: null,
  customDisclaimer: null,
  grayedText: false,
  restaurantName: null,
  showGiveawayTerms: false,
};

FollowDisclaimer.propTypes = {
  align: PropTypes.oneOf(['center', 'left', 'right']),
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  customDisclaimer: PropTypes.string,
  grayedText: PropTypes.bool,
  openTermsModal: PropTypes.func.isRequired,
  restaurantName: PropTypes.string,
  showGiveawayTerms: PropTypes.bool,
};

export default compose(
  connect(
    () => ({}),
    {
      openTermsModal,
    },
  ),
  withStyles(followDisclaimerStyles),
  withRestaurant,
  mapProps(({ customDisclaimer, restaurant, ...props }) => ({
    ...props,
    customDisclaimer: customDisclaimer || (restaurant && restaurant.customDisclaimer),
    restaurantId: restaurant && restaurant.id,
    restaurantName: restaurant && restaurant.name,
  })),
)(FollowDisclaimer);
