import { hexToRgba, rgbaToHex } from '../../../../utils/colors';

export default theme => ({
  accordionSummaryText: {
    fontWeight: 'bold',
  },
  allItemsContainer: {
    flexDirection: 'column',
  },
  blueCheck: {
    color: '#0A6AE2',
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
  },
  blueGiftCardBox: {
    '& a': {
      color: '#000',
      fontSize: theme.spacing(1.5),
      textDecoration: 'underline',
    },
    backgroundColor: '#DCF1FA',
    color: '#000',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1),
  },
  boxButtons: {
    marginLeft: 'auto',
    textAlign: 'right',
  },
  boxText: {
    alignItems: 'center',
    display: 'flex',
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
  },
  closeMyTab: {
    margin: theme.spacing(2, 0, 4, 0),
  },
  closeMyTabText: {
    fontWeight: 'bold',
  },
  closeTabButton: {
    marginBottom: theme.spacing(2),
  },
  dashedDivider: {
    border: '2px dashed',
    color: '#E5E5E5',
  },
  expandIcon: {
    color: theme.palette.primary.main,
  },
  followDisclaimer: {
    '& button': {
      position: 'relative',
      top: '-1px',
    },
    '& p': {
      fontSize: '.75rem',
    },
    alignItems: 'center',
    color: '#B0B0B0',
    display: 'flex',
    marginTop: theme.spacing(1),
  },
  giftCardContainer: {
    backgroundColor: theme.palette.gray.light,
    borderRadius: theme.shape.borderRadius,
    display: 'inline-block',
    margin: theme.spacing(1, 0),
    maxWidth: '240px',
    padding: theme.spacing(1),
    width: '100%',
  },
  giftCardRemainingBalance: {
    color: theme.palette.gray.dark,
    display: 'flex',
    justifyContent: 'space-between',
  },
  greenCheck: {
    color: '#109F00',
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
  },
  greenOfferBox: {
    '& a': {
      color: '#000',
      fontSize: theme.spacing(1.5),
      textDecoration: 'underline',
    },
    backgroundColor: '#E8F7E8',
    color: '#000',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1),
  },
  greyInfoBox: {
    backgroundColor: theme.palette.grey[300],
    color: '#686868',
    margin: theme.spacing(0, -2),
    padding: theme.spacing(1),
  },
  greyInfoBoxText: {
    fontSize: '0.8rem',
  },
  itemDetails: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 1),
  },
  menuItemDiscount: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  menuItemTotal: {
    fontSize: '1.25em',
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(1),
    textAlign: 'right',
  },
  menuItemValue: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  menuItemValueContainer: {
    display: 'inline-block',
    margin: theme.spacing(1, 0),
    maxWidth: '240px',
    width: '100%',
  },
  minimumBox: {
    '& svg': {
      fontSize: 'x-large',
      marginRight: '5px',
      verticalAlign: 'middle',
    },
    color: '#FF0000',
    fontSize: '13px',
    margin: '10px',
  },
  noPadding: {
    padding: '0 0 0 0 !important',
  },
  offerCodeButton: {
    height: '100%',
  },
  paymentDescriptionBox: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(0, 2),
  },
  paymentDescriptionHeading: {
    '& svg': {
      color: theme.palette.green.main,
      fontSize: '1.25rem',
      marginLeft: theme.spacing(1),
    },
  },
  paymentDescriptionHeadingText: {
    fontWeight: 'bold',
  },
  paymentDescriptionText: {
    '& p': {
      marginLeft: theme.spacing(1),
    },
    color: '#B8B8B8',
    margin: theme.spacing(1, 0),
  },
  pickupDisclaimer: {
    color: '#CC0000',
  },
  primaryInfoBox: {
    backgroundColor: hexToRgba(rgbaToHex(theme.palette.grey[400]).color, 40),
    padding: theme.spacing(2),
  },
  primaryInfoBoxText: {
    fontSize: '0.8rem',
  },
  qualifyingItemsNotice: {
    '& svg': {
      color: '#FF0000',
      fontSize: 'x-large',
      marginRight: theme.spacing(0.5),
      verticalAlign: 'middle',
    },
    display: 'flex',
    margin: theme.spacing(1.25, 1.25, 0, 1.25),
    textAlign: 'unset',
  },
  qualifyingItemsText: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: theme.spacing(1.75),
  },
  seeQualifyingItems: {
    '&:hover': {
      backgroundColor: `${theme.palette.common.white} !important`,
      opacity: 0.8,
    },
    padding: '0',
    textDecoration: 'underline',
    width: 'fit-content',
  },
  submitButtonContainerMobile: {
    alignItems: 'center',
    backgroundColor: 'white',
    bottom: 0,
    boxShadow: '0px -10px 15px rgb(242, 242, 242)',
    display: 'flex',
    flexDirection: 'column',
    left: 0,
    margin: theme.spacing(0, -2),
    padding: theme.spacing(3, 2),
    position: 'sticky',
    zIndex: 2,
  },
  tabName: {
    fontWeight: 'bold',
  },
  totalContainer: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
    padding: theme.spacing(2, 2, 0),
    textAlign: 'right',
  },
  vipBox: {
    margin: theme.spacing(3, 0),
  },
  vipCheckboxTitle: {
    fontSize: '1.25rem',
    marginTop: theme.spacing(4),
  },
  vipCheckboxWrapper: {
    alignItems: 'center',
    display: 'inline-flex',
  },
});
