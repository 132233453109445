import React, { useEffect, useMemo, useState } from 'react';
import { compose, mapProps } from '@shakacode/recompose';
import PropTypes from 'prop-types';
import { payments } from '@square/web-sdk';

import { withSnackbar } from '../../utils/withSnackbar';
import { withPopmenuConfig } from '../../utils/withPopmenuConfig';

export const SquarePaymentContext = React.createContext({});

const SquarePaymentsForm = ({
  children,
  showSnackbarError,
  squareApplicationId,
  squareJsUrl,
  squareLocationId,
}) => {
  const [paymentSdk, setPaymentSdk] = useState(null);
  const [squareCard, setSquareCard] = useState(null);

  useEffect(() => {
    if (paymentSdk) {
      return;
    }

    const loadPayments = async () => {
      try {
        // initialize payment
        const payment = await payments(squareApplicationId, squareLocationId, { scriptSrc: squareJsUrl });
        setPaymentSdk(payment);

        // initialize card
        const card = await payment.card();
        setSquareCard(card);

        // attach card to DOM
        await card.attach('#square-card-container');
      } catch (error) {
        showSnackbarError(error.message);
      }
    };

    if (squareApplicationId && squareLocationId && squareJsUrl) {
      loadPayments();
    }
  }, [squareApplicationId, squareJsUrl, squareLocationId]); // eslint-disable-line react-hooks/exhaustive-deps

  const value = useMemo(
    () => ({
      paymentSdk,
      squareCard,
    }), [paymentSdk, squareCard],
  );

  return (
    <SquarePaymentContext.Provider value={value}>
      {children}
    </SquarePaymentContext.Provider>
  );
};

SquarePaymentsForm.propTypes = {
  children: PropTypes.node.isRequired,
  showSnackbarError: PropTypes.func.isRequired,
  squareApplicationId: PropTypes.string.isRequired,
  squareLocationId: PropTypes.string.isRequired,
};

export default compose(
  withSnackbar,
  withPopmenuConfig,
  mapProps(({ popmenuConfig, ...props }) => ({
    ...props,
    squareJsUrl: popmenuConfig.squareJsUrl,
  })),
)(SquarePaymentsForm);
